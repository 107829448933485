import { AUTHORIZE_PROPOSAL_FORM, REMOVE_AUTHORIZATION_PROPOSAL_FORM } from './authConstants';

export function authorizeProposalForm(proposalForm) {
  return {
      type: AUTHORIZE_PROPOSAL_FORM,
      payload: proposalForm,
  }
}

export function removeAuthorizationProposalForm() {
  return {
    type: REMOVE_AUTHORIZATION_PROPOSAL_FORM,
  };
}
