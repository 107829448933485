export const lookups = {
    preferredLanguage: [
        { value: 1, label: "English" },
        { value: 2, label: "Afrikaans" },
    ],

    preferredCommunication: [
        { value: 1, label: "Email" },
        { value: 2, label: "Call" },
        { value: 3, label: "SMS" },
        { value: 4, label: "WhatsApp" },
    ],

    companyType: [
        { value: 1, label: "Close Corporation" },
        { value: 2, label: "Sole Proprietor" },
        { value: 3, label: "Pty Ltd" },
    ],

    yesno: [
        { value: 0, label: "No" },
        { value: 1, label: "Yes" },
    ],

    yesnoReplaceYes: [
        { value: 0, label: "No" },
        { value: 1, label: "Yes" },
    ],

    reasonRequestingQuote: [
        { value: 1, label: "New Business" },
        { value: 2, label: "Renewal" },
        { value: 3, label: "Current insurer corrective terms" },
        { value: 4, label: "Current insurer cancellation" },
    ],

    underwriting: [
        { value: 1, label: "Short Haul" },
        { value: 2, label: "Long Haul" },
        { value: 3, label: "Cross Border" },
        { value: 4, label: "Overnight express between 23h00 and 04h00" },
    ],

    provinces: [
        { value: 'Countrywide', label: "Countrywide" },
        { value: 'LIM', label: "Limpopo" },
        { value: "NW", label: "North West" },
        { value: "GP", label: "Gauteng" },
        { value: "MP", label: "Mpumalanga" },
        { value: "FS", label: "Free State" },
        { value: "KZN", label: "KwaZulu-Natal" },
        { value: "EC", label: "Eastern Cape" },
        { value: "NC", label: "Northern Cape" },
        { value: "WC", label: "Western Cape" },
    ],

    operatingHours: [
        { value: 1, label: "24 Hours" },
        { value: 2, label: "Normal Working Hours (8:00 - 17:00)" },
    ],

    telematics: [
        { value: 1, label: "Two way radios" },
        { value: 2, label: "Defence drive training" },
        { value: 3, label: "Vehicles in convy" },
        { value: 4, label: "Armed escorts" },
    ],

    sercurityMeasures: [
        { value: 1, label: "Fence" },
        { value: 2, label: "Locked Gate" },
        { value: 3, label: "Electric Fence" },
        { value: 4, label: "Camera Surveillance" },
        { value: 5, label: "Guards" },
    ],

    vehiclesParked: [
        { value: 1, label: "24 Hours Recognized Truck Stop" },
        { value: -1, label: "Other" },
    ],

    vehiclesOwner: [
        { value: 1, label: "Insured" },
        { value: 2, label: "Bank" },
        { value: 3, label: "Third Party" },
    ],

    truckDrivers: [
        { value: 1, label: "Owner Driver" },
        { value: 2, label: "Specified Driver and Co Driver" },
        { value: 3, label: "General Drivers in the employment of Insured" },
        { value: 4, label: "Casual or Part-time Drivers" },
    ],

    motorInsurance: [
        { value: 1, label: "Motor Comprehensive" },
        { value: 2, label: "Third Party, Fire and Theft" },
        { value: 3, label: "Third Party Only" },
    ],

    motorExtensions: [
        { value: 1, label: "Own Damage Excess Reducers" },
        { value: 2, label: "Theft & Hijack Excess Reducers" },
        { value: 3, label: "Third Party Excess Reducers" },
        { value: 4, label: "Windscreen" },
        { value: 5, label: "Windscreen Excess Reducers" },
        { value: 6, label: "Credit Shortfall" },
        { value: 7, label: "Non-Accidental Towing" },
        { value: 8, label: "Loss of Use – Weeks (4-20) and Benefit(R1,000-R20,000) required" },
    ],

    gitInsurance: [
        { value: 1, label: "All Risk" },
        { value: 2, label: "Fire / Collision / Overturning" },
        { value: 3, label: "Fire / Collision / Overturning / Theft / Hijack" },
    ],

    gitExtensions: [
        { value: 1, label: "Basic Excess Helper" },
        { value: 2, label: "Theft & Hijack Excess Helper" },
        { value: 3, label: "All Risk Tarps, Nets, Ropes and Chains R30,000 max limit of Indemnity" },
        { value: 4, label: "Increased Debris Removal R50,000 max limit of Indemnity" },
        { value: 5, label: "Deterioration of Stock" },
    ],

    goodsCarried: [
        { value: 1, label: "Alcohol" },
        { value: 2, label: "Beer/Wine" },
        { value: 3, label: "Cell phones" },
        { value: 4, label: "Cigarettes" },
        { value: 5, label: "Clothing & Footwear" },
        { value: 6, label: "Coal/Iron Ore" },
        { value: 7, label: "Copper or any other Non-Ferrous Metals" },
        { value: 8, label: "Electronic/Electrical Goods" },
        { value: 9, label: "General Household Items" },
        { value: 10, label: "Livestock" },
        { value: 11, label: "Medical Supplies" },
        { value: 12, label: "Pre-Paid Phone Cards" },
        { value: 13, label: "Refrigerated Foodstuff/Goods" },
        { value: 14, label: "Sand/Stone/Bricks" },
        { value: 15, label: "Tinned Fish" },
        { value: 16, label: "Tyres" },
    ],

    countries: [
        {label: 'Algeria', value: 'DZ'},
        {label: 'Angola', value: 'AO'},
        {label: 'Benin', value: 'BJ'},
        {label: 'Botswana', value: 'BW'},
        {label: 'Burkina Faso', value: 'BF'},
        {label: 'Burundi', value: 'BI'},
        {label: 'Cameroon', value: 'CM'},
        {label: 'Cape Verde', value: 'CV'},
        {label: 'Central African Republic', value: 'CF'},
        {label: 'Chad', value: 'TD'},
        {label: 'Comoros', value: 'KM'},
        {label: 'Democratic Republic of the Congo', value: 'CD'},
        {label: 'Djibouti', value: 'DJ'},
        {label: 'Egypt', value: 'EG'},
        {label: 'Equatorial Guinea', value: 'GQ'},
        {label: 'Eritrea', value: 'ER'},
        {label: 'Ethiopia', value: 'ET'},
        {label: 'Gabon', value: 'GA'},
        {label: 'Gambia', value: 'GM'},
        {label: 'Ghana', value: 'GH'},
        {label: 'Guinea', value: 'GN'},
        {label: 'Guinea-Bissau', value: 'GW'},
        {label: "Côte d'Ivoire", value: 'IC'},
        {label: 'Kenya', value: 'KE'},
        {label: 'Lesotho', value: 'LS'},
        {label: 'Liberia', value: 'LR'},
        {label: 'Libya', value: 'LY'},
        {label: 'Madagascar', value: 'MG'},
        {label: 'Malawi', value: 'MW'},
        {label: 'Mali', value: 'ML'},
        {label: 'Mauritius', value: 'MU'},
        {label: 'Mayotte', value: 'YT'},
        {label: 'Morocco', value: 'MA'},
        {label: 'Mozambique', value: 'MZ'},
        {label: 'Namibia', value: 'NA'},
        {label: 'Niger', value: 'NE'},
        {label: 'Nigeria', value: 'NG'},
        {label: 'Rwanda', value: 'RW'},
        {label: 'Saint Helena', value: 'SH'},
        {label: 'Sao Tome and Principe', value: 'ST'},
        {label: 'Senegal', value: 'SN'},
        {label: 'Seychelles', value: 'SC'},
        {label: 'Sierra Leone', value: 'SL'},
        {label: 'Somalia', value: 'SO'},
        {label: 'South Africa', value: 'ZA'},
        {label: 'Sudan', value: 'SD'},
        {label: 'South Sudan', value: 'SS'},
        {label: 'Tanzania', value: 'TZ'},
        {label: 'Togo', value: 'TG'},
        {label: 'Tunisia', value: 'TN'},
        {label: 'Uganda', value: 'UG'},
        {label: 'Western Sahara', value: 'EH'},
        {label: 'Zambia', value: 'ZM'},
        {label: 'Zimbabwe', value: 'ZW'}
    ],
}
