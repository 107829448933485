import React from "react";
import { useSelector } from "react-redux";

import ModalNotification from "../../../features/modals/ModalNotification";
import ModalPassword from "../../../features/modals/ModalPassword";

export default function ModalManager() {
    const modalLookup = {
        ModalNotification,
        ModalPassword
    };

    const currentModal = useSelector((state) => state.modals);
    let renderedModal;

    if (currentModal) {
        const { modalType, modalProps } = currentModal;
        const ModalComponent = modalLookup[modalType];
        renderedModal = <ModalComponent {...modalProps} />;
    }

    return <span>{renderedModal}</span>;
}
