import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./app/layout/App";
import reportWebVitals from "./reportWebVitals";
import ErrorBoundary from "./app/layout/ErrorBoundary";
import { Provider } from "react-redux";
import { configureStore, history } from "./app/store/configureStore";
import { ConnectedRouter } from "connected-react-router";
import axios from "axios";

const store = configureStore();

axios.defaults.withCredentials = true;
const rootEl = document.getElementById("root");

function render() {
    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <ErrorBoundary>
                    <App />
                </ErrorBoundary>
            </ConnectedRouter>
        </Provider>,
        rootEl
    );
}

if (module.hot) {
    module.hot.accept("./app/layout/App", function () {
        setTimeout(render);
    });
}

render();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
