import { AUTHORIZE_PROPOSAL_FORM, REMOVE_AUTHORIZATION_PROPOSAL_FORM } from './authConstants';

const initialState = {
  authenticated: false,
  proposalForm: {}
};

export default function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case AUTHORIZE_PROPOSAL_FORM:
      return {
        ...state,
        authenticated: true,
        proposalForm: payload,
      };   
    case REMOVE_AUTHORIZATION_PROPOSAL_FORM:
      return {
        ...state,
        authenticated: false,
      };    
    default:
      return state;
  }
}
