import React, { useEffect, useState } from "react";
import axios from "axios";

export default function ViewPDF(props) {
    const [data, setData] = useState("");
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        (
            async () => {
                // const {data} = await axios.get(process.env.REACT_APP_API_URL + "quote/pdf/" + props.match.params.id, {});
                const {data} = await axios.post(process.env.REACT_APP_API_URL + "quote/pdf/" + props.match.params.id, {}, {responseType: 'blob'});
                // const blob = new Blob([data], {type: 'text/pdf'});
                const url = window.URL.createObjectURL(data);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'test.pdf';
                link.click();
                setData(data);
                setLoading(false);
                console.log(data)
            }
        )();
    });


    if (loading === true) {
        return "Loading...";
    }

    return (
        <div style={{ height: '100vh'}}>
            <iframe
                title="file"
                style={{ width: '100%', height: '100%' }}
                src={data}
                />
        </div>
    )
};