import React from "react";
import { Route } from "react-router-dom";

import ModalManager from "../common/modals/ModalManager";
import NavBar from "../../features/nav/NavBar";
import LoginForm from "../../features/auth/LoginForm";
import ShortQuotesEdit from "../../features/shortQuotes/ShortQuotesEdit";
import ShortQuotesCreate from "../../features/shortQuotes/ShortQuotesCreate";
import ShortQuotesView from "../../features/shortQuotes/ShortQuotesView";
import ShortQuotesProposalForm from "../../features/shortQuotes/ShortQuotesProposalForm";
import GapQuotesEdit from "../../features/gapQuotes/GapQuotesEdit";
import GapQuotesCreate from "../../features/gapQuotes/GapQuotesCreate";
import GapQuotesView from "../../features/gapQuotes/GapQuotesView";
import ViewPDF from "../../features/pdf/ViewPDF";

export default function App() {
    
    return (
        <>
            <ModalManager />
            <Route exact path="/" component={LoginForm} />
            <Route exact path="/proposal-form/:access_key/:id" component={ShortQuotesProposalForm} />
            {/* <Route exact path="/" component={QuotesProposalForm} /> */}
            <Route exact path="/login" component={LoginForm} />

            <Route
                path={["/quotes", "/gap-quotes"]}
                render={() => (
                    <>
                        <NavBar />

                        <div>                            
                            <Route exact path="/quotes" component={ShortQuotesView} />
                            <Route exact path="/quotes/create" component={ShortQuotesCreate} />
                            <Route exact path="/quotes/edit/:id" component={ShortQuotesEdit} />
                            <Route exact path="/quotes/pdf/:id" component={ViewPDF} />
                        </div>
                        <div>                            
                            <Route exact path="/gap-quotes" component={GapQuotesView} />
                            <Route exact path="/gap-quotes/quick-quote" component={GapQuotesCreate} />
                            <Route exact path="/gap-quotes/create" component={GapQuotesCreate} />
                            <Route exact path="/gap-quotes/edit/:id" component={GapQuotesEdit} />
                            <Route exact path="/gap-quotes/pdf/:id" component={ViewPDF} />
                        </div>
                    </>
                )}
            />
        </>
    );
}
