import React from "react";
import { useField } from "formik";

export default function CustomCheckbox({ label, size, ...props }) {
    const [field] = useField(props);
    var inputSize = "";
    (size) ? inputSize = size : inputSize = "w-full";
    
    return (
        <div className="mt-1">
            <div className={`relative ${inputSize} flex items-start`}>
                <div className="flex items-center h-5">
                    <input 
                        {...field} {...props}
                        id={field.name}
                        type="checkbox"
                        className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                    />
                </div>
                <div className="ml-3 text-sm">
                    <label htmlFor={field.name}>
                        {label}
                    </label>
                </div>
            </div>
        </div>
    );
}
