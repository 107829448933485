import React from "react";
import Select from 'react-select'

export default function CustomSelect ({ label, onChange, options, form, required, field, multi, size, ...props}) {
    var [inputSize, placeholder] = "";
    (size) ? inputSize = size : inputSize = "w-full";
    (multi) ? placeholder = "Choose options" : placeholder = "Choose an option";

    // overwriting default styles
    const customStyles = {
        control: (base, state) => ({
          ...base,
          borderColor: 'rgb(156, 163, 175)',
          borderRadius: 6,
          padding: 0,
          fontSize: 14,
          boxShadow: state.isFocused ? null : null
        }),
        menuList: base => ({
          ...base,
          fontSize: 14,
          padding: 0
        })
    };

    // commented out as we are temporarily using lookup.js file to get all lookup data
    // const preferredCommunicationOptions = [
    //     { value: 'Email', label: 'Email' },
    //     { value: 'Call', label: 'Call' },
    //     { value: 'SMS', label: 'SMS' },
    //     { value: 'WhatsApp', label: 'WhatsApp' },
    // ];

    // const companyTypeOptions = [
    //     { value: 1, label: 'Close Corporation' },
    //     { value: 2, label: 'Sole Proprietor' },
    //     { value: 3, label: 'Pty Ltd' },
    // ];
    // const yesnoOptions = [
    //     { value: -1, label: 'No' },
    //     { value: "", label: 'Yes' },
    // ];

    // if (typeof options === 'string') {
    //     options = eval(options + "Options");
    // }
    var getValue = (options) => {
        if (multi) {
            if (field.value !== undefined && field.value !== null)  {
                var valueArray = field.value.split(',');
                var selectedValues = [];
                valueArray.forEach(value => {
                    // parse value to int if it contains any numbers 
                    if (/^\d+$/.test(value)) {
                        value = parseInt(value);
                    }
                    selectedValues.push(options.find(option => option.value === value));
                });
                return selectedValues;
            }
        } else {
            return options.find(option => option.value === field.value);
        }
    }
    var handleChange = (selectedOption) => {
        form.setFieldValue(field.name, selectedOption.value)
    }

    // overwrite usual handle change if multi
    if (multi) {
        handleChange = (selectedOptions) => {
            // map out all the values so that we have one string of values in array
            var valuesArray = selectedOptions.map(e => JSON.stringify(e.value));
            // remove all the symbols and spaces to only have the string seperated by comma
            var values = valuesArray.join(",").replace(/["']+/g, "");
            form.setFieldValue(field.name, values);
        }
    }

    return (
        <div className="py-2">
            <div className={`flex justify-between ${inputSize}`}>
                { label && (
                    <label htmlFor={field.name} className="block text-sm font-medium text-gray-700">
                        {label}
                    </label>
                )}
                { required && (
                    <span className="text-sm text-gray-400" id={field.name}>
                        Required
                    </span>
                )}
                { multi && (
                    <span className="text-sm text-gray-400" id={field.name}>
                        Multiple
                    </span>
                )}
            </div>
            <div className="mt-1 relative rounded-md shadow-sm">
                <Select
                    options={options}
                    name={field.name}
                    value={getValue(options)}
                    onChange={handleChange}
                    isMulti={multi}
                    onBlur={field.onBlur}
                    placeholder={placeholder}
                    styles={customStyles}
                    className={`${size}`}
                />
            </div>
        </div>
    );
}
