import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import modalReducer from "../common/modals/modalReducer";
import authReducer from '../../features/auth/authReducer';

const rootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        modals: modalReducer,
        auth: authReducer,
    });

export default rootReducer;
