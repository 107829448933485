import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import QuoteTable from "../tables/QuoteTable";
import LoadingComponent from "../layout/LoadingComponent";
import { format } from 'date-fns'

export default function GapQuotesView() {
    const [data, setData] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (
            async () => {
                const {data} = await axios.get(process.env.REACT_APP_API_URL + "gap-quotes", {});
                setData(data);
                setLoading(false);
            }
        )();
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
                show: false,
            },
            {
                Header: "Date / Time",
                accessor: "added_dt",
                Cell: ({ value}) => { return format(new Date(value), 'dd MMMM yyyy / HH:mm')}
            },
            {
                Header: "Consultant",
                accessor: "added_by",
            },
            {
                Header: "Initials",
                accessor: "initials",
            },
            {
                Header: "Surname",
                accessor: "surname",
            },
            {
                Header: "Quote Ref",
                accessor: "quote_ref",
            },
            {
                Header: "Quote Type",
                accessor: "type",
            },
        ],
        []
    );

    if (loading === true) {
        return <LoadingComponent />
    }

    return (
        <div className="block-container w-11/12">
            {data && (
                <QuoteTable
                    columns={columns}
                    data={data}
                    linkTo={"/gap-quotes/edit"}
                />
            )}
        </div>
    );
}
