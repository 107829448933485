import React, { useEffect, useState } from "react";
import { openModal } from "../../app/common/modals/modalReducer";
import { authorizeProposalForm } from "../auth/authActions";
import axios from "axios";
import CustomText from "../../app/common/form/CustomText";
import CustomTextArea from "../../app/common/form/CustomTextArea";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field, FieldArray} from "formik";
import * as Yup from 'yup';
import LoadingComponent from "../layout/LoadingComponent";
import CustomSelect from "../../app/common/form/CustomSelect";
import { faCarCrash, faCarMechanic, faCars, faClipboardList, faEnvelope, faFileDownload, faFilePdf, faHouse, faPlusCircle, faSave, faTrashAlt, faTruckLoading, faUser } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomButton from "../../app/common/form/CustomButton";
import ExcelImport from "../../app/common/import/ExcelImport"

const navigation = [
    { name: 'Overview', href: '#overview', icon: faHouse, current: true, count: 1 },
    { name: 'Basic Information', href: '#basic_information', icon: faUser, current: false, count: 2 },
    { name: 'General Material Disclosures', href: '#general_material_disclosures', icon: faClipboardList, current: false, count: 3 },
    { name: 'Vehicle Details', href: '#vehicle_details', icon: faCarMechanic, current: false, count: 4 },
    { name: 'Claims History', href: '#claims_history', icon: faCarCrash, current: false, count: 5 },
    { name: 'Vehicles', href: '#vehicles', icon: faCars, current: false, count: 6 },
    { name: 'GIT Details', href: '#git_details', icon: faTruckLoading, current: false, count: 7 }
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function ShortQuotesEdit(props) {
    const dispatch = useDispatch();
    const [openTab, setOpenTab] = React.useState(1);
    const quoteID = props.match.params.id;
    const { proposalForm } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(true);
    const [insurers, setInsurers] = useState([]);
    const [lookups, setLookups] = useState([]);


    useEffect(() => {
        (
            async () => {
                // prevent infinite loop
                if (loading) {
                    const {data} = await axios.get(process.env.REACT_APP_API_URL + "quotes/" + quoteID, {});
                    dispatch(authorizeProposalForm(data));

                    const insurers = await axios.get(process.env.REACT_APP_API_URL + "schemes", {});
                    setInsurers(insurers.data);

                    const lookupsResponse = await axios.get(process.env.REACT_APP_API_URL + "lookups", {});
                    setLookups(lookupsResponse.data);
                }

                setLoading(false);
            }
        )();
    });

    const downloadAllInformation = async() => {
        try {
            setLoading(true);
            axios({
                url: process.env.REACT_APP_API_URL + "quotes/pdf/" + quoteID,
                method: 'POST',
                responseType: 'blob'
            }).then((response) => {
                setLoading(false);
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');

                link.href = url;
                link.target = "_blank";
                // link.download = response.headers['x-suggested-filename'];
                link.click();
            });
        }
        catch(error) {
            setLoading(false);
            dispatch(openModal({modalType: "ModalNotification", modalProps: {
                type: 'error',
                heading: 'Something went wrong',
                body: 'Please try again',
                closeText: 'Close',
            }}))
        }
    }

    const mailForm = async() => {
        try {
            setLoading(true);
            axios({
                url: process.env.REACT_APP_API_URL + "quotes/email/" + quoteID + "/request",
                method: 'POST',
            }).then((response) => {
                setLoading(false);
            });

        } catch (error) {            
            setLoading(false);
        }
    }

    if (loading === true) {
        return <LoadingComponent />
    }

    return (
        <Formik
            initialValues={proposalForm}
            enableReinitialize="true"
            validationSchema={Yup.object({
                company_depos: Yup.number()
                                    .max(50, 'The number of depos cannot be more than 50'),
                company_name: Yup.string()
                                    .required('The company name is required'),
                contact_title: Yup.string()
                                    .max(5, 'The title cannot be more than 5 characters'),
                contact_initials: Yup.string()
                                    .max(5, 'The initials cannot be more than 5 characters'),
                contact_cellphone: Yup.string()
                                    .required('The cellphone is required')
                                    .matches(/[\d]$/, 'Cellphone cannot have spaces or symbols'),
                contact_email: Yup.string()
                                    .required('The email is required')
                                    .email('The email must be a valid email'),
            })}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
                // information for the quote
                const formData = {
                    company_name:                       values.company_name,
                    company_vat_number:                 values.company_vat_number,
                    company_physical_address:           values.company_physical_address,
                    company_trading_name:               values.company_trading_name,
                    company_telephone:                  values.company_telephone,
                    company_postal_address:             values.company_postal_address,
                    company_registration_number:        values.company_registration_number,
                    company_email:                      values.company_email,
                    company_description:                values.company_description,
                    company_type:                       values.company_type,
                    company_depos:                      values.company_depos,
                    company_other_trading_name:         values.company_other_trading_name,

                    contact_preferred_language:         values.contact_preferred_language,
                    contact_title:                      values.contact_title,
                    contact_email:                      values.contact_email,
                    contact_position:                   values.contact_position,
                    contact_initials:                   values.contact_initials,
                    contact_id_number:                  values.contact_id_number,
                    contact_address:                    values.contact_address,
                    contact_first_name:                 values.contact_first_name,
                    contact_work_tel:                   values.contact_work_tel,
                    contact_preferred_communication:    values.contact_preferred_communication,
                    contact_surname:                    values.contact_surname,
                    contact_cellphone:                  values.contact_cellphone,

                    general_insurer_declined:           values.general_insurer_declined,
                    general_insurer_declined_policy:    values.general_insurer_declined_policy,
                    general_insurer_declined_reasons:   values.general_insurer_declined_reasons,
                    general_area_operation:             values.general_area_operation,
                    general_insurer_cancelled:          values.general_insurer_cancelled,
                    general_insurer_cancelled_policy:   values.general_insurer_cancelled_policy,
                    general_insurer_cancelled_reasons:  values.general_insurer_cancelled_reasons,
                    general_operating_hours:            values.general_operating_hours,
                    general_reason_requesting:          values.general_reason_requesting,
                    general_foreign_drivers:            values.general_foreign_drivers,
                    general_underwriting:               values.general_underwriting,
                    general_cross_border_areas:         values.general_cross_border_areas,
                    general_telematics:                 values.general_telematics,

                    vehicles_security:                  values.vehicles_security,
                    vehicles_parked:                    values.vehicles_parked,
                    vehicles_driven_by:                 values.vehicles_driven_by,
                    vehicles_registered_name:           values.vehicles_registered_name,

                    motor_insurance_requested:          values.motor_insurance_requested,
                    motor_extensions_requested:         values.motor_extensions_requested,

                    git_insurance:                      values.git_insurance,
                    git_goods_carried:                  values.git_goods_carried,
                    git_sub_contractor:                 values.git_sub_contractor,
                    git_cover:                          values.git_cover,
                    git_extensions:                     values.git_extensions,

                    territorial_limits:                 values.territorial_limits,

                    // Arrays
                    drivers:                            values.drivers,
                    claims:                             values.claims,
                    vehicles:                           values.vehicles,
                    sub_contractors:                    values.sub_contractors,
                }

                try {
                    await axios.put(process.env.REACT_APP_API_URL + "quotes/" + quoteID, formData)
                    .then(() => {
                        dispatch(openModal({modalType: "ModalNotification", modalProps: {
                            type: 'success',
                            heading: 'Success',
                            body: 'The data has been updated successfully',
                            closeText: 'Close',
                        }}));
                    });
                } catch (error) {
                    dispatch(openModal({modalType: "ModalNotification", modalProps: {
                        type: 'error',
                        heading: 'Something went wrong',
                        body: 'Please try again',
                        closeText: 'Close',
                    }}))
                }
            }}
        >
            {({isSubmitting, errors, values}) => (
                <Form>
                    <div className="flex">
                        {/* Tabs */}
                        <nav className="space-y-1" aria-label="Sidebar">
                            {navigation.map((item) => (
                                <a
                                    key={item.name}
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(item.count);
                                    }}
                                    href={item.href}
                                    className={classNames(
                                        openTab === item.count ? 'bg-blue-comparemed-dark text-white' : 'text-blue-comparemed-dark hover:bg-blue-comparemed-default hover:text-white',
                                        'group flex items-center px-3 py-2 text-sm font-medium'
                                    )}
                                    role="tablist"
                                    data-toggle="tab"
                                >
                                    {/* Get font awesome icons aligned with the buttons then finish up with displaying the information on the left nav bar */}
                                    <FontAwesomeIcon
                                        className={classNames(
                                            openTab === item.count ? 'text-white' : 'hover:bg-blue-comparemed-default hover:text-white',
                                            'flex-shrink-0 -ml-1 mr-3 h-6 w-8'
                                        )}
                                        aria-hidden="true"
                                        icon={item.icon}
                                    />
                                    <span className="truncate">{item.name}</span>
                                </a>
                            ))}

                            <div className="mt-8">
                                <h3 className="px-3 py-5 text-xs font-semibold text-gray-500 uppercase tracking-wider" id="projects-headline">
                                    Quick Actions
                                </h3>
                                <div className="space-y-1" aria-labelledby="projects-headline">
                                    <CustomButton
                                        className="text-blue-comparemed-dark hover:bg-blue-comparemed-default hover:text-white group flex items-center px-3 py-2 text-sm font-medium w-full"
                                        type="button"
                                        onClick={downloadAllInformation}
                                        icon={faFilePdf}
                                        text={"Download PDF"}
                                    />
                                    <button
                                        className="w-full text-blue-comparemed-dark hover:bg-blue-comparemed-default hover:text-white group flex items-center px-3 py-2 text-sm font-medium"
                                        type="button"
                                        onClick={mailForm}
                                    >
                                        <FontAwesomeIcon
                                            className='flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                                            aria-hidden="true"
                                            icon={faEnvelope}
                                        />
                                        <span className="truncate">Email Form</span>
                                    </button>
                                    <CustomButton
                                        className="w-full text-blue-comparemed-dark hover:bg-blue-comparemed-default hover:text-white group flex items-center px-3 py-2 text-sm font-medium"
                                        disabled={isSubmitting}
                                        submitBool={isSubmitting}
                                        type="submit"
                                        icon={faSave}
                                        text={"Save"}
                                    />
                                </div>
                            </div>
                        </nav>
                        <div className="block-container h-auto w-5/6 p-5">
                            <div className="px-4 py-5 flex-auto">
                                <div className="tab-content tab-space">
                                    {/* Tab 1 - overview */}
                                    <div className={openTab === 1 ? "block" : "hidden"} id="overview">
                                        {/* Company Details */}
                                        <div className="input-container">
                                            <div className="py-2 px-2">
                                                <p>Company name</p>
                                                <p>{values.company_name}</p>
                                            </div>
                                            <div className="py-2 px-2">
                                                <p>Contact person</p>
                                                <p>{values.contact_title} {values.contact_initials} {values.contact_first_name} {values.contact_surname}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Tab 2 - Information */}
                                    <div className={classNames(openTab === 2 ? "block" : "hidden", "overflow-y-auto")} id="basic_information">
                                        <div className="flex flex-wrap">
                                            <div className="input-container">
                                                <Field
                                                    label="Preferred language"
                                                    options={lookups.preferredLanguage}
                                                    name='contact_preferred_language'
                                                    component={CustomSelect}
                                                />
                                            </div>
                                            <div className="input-container">
                                                <Field
                                                    label="Preferred insurer"
                                                    options={insurers}
                                                    name='schemes'
                                                    component={CustomSelect}
                                                    multi
                                                />
                                            </div>
                                        </div>
                                        {/* Company Details */}
                                        <h2 className="py-10">Proposer/Client Details</h2>
                                        <div className="flex flex-wrap">
                                            <div className="input-container">
                                                <CustomText label="Company name" name="company_name" type="text" required/>
                                                <CustomText label="VAT number" name="company_vat_number" type="text"/>
                                                <CustomTextArea label="Physical address" name="company_physical_address" />
                                            </div>
                                            <div className="input-container">
                                                <CustomText label="Trading name (if applicable)" name="company_trading_name" type="text"/>
                                                <CustomText label="Telephone" name="company_telephone" type="text"  />
                                                <CustomTextArea label="Postal address" name="company_postal_address"/>
                                            </div>
                                            <div className="input-container">
                                                <CustomText label="Company registration number/CK Number" name="company_registration_number" type="text"/>
                                                <CustomText label="Email" name="company_email" type="email" />
                                                <CustomTextArea label="Description of business" name="company_description" />
                                            </div>
                                            <div className="input-container">
                                                <Field
                                                    label="Type"
                                                    options={lookups.companyType}
                                                    name='company_type'
                                                    component={CustomSelect}
                                                />
                                                <CustomText label="Number of depos" name="company_depos" type="number" />
                                                <Field
                                                    label="Has anyone ever traded under a different name?"
                                                    options={lookups.yesnoReplaceYes}
                                                    name='company_other_trading_name'
                                                    component={CustomSelect}
                                                />
                                                {values.company_other_trading_name === 1 && (
                                                    <CustomText label="Name" name="company_other_trading_name_details" type="text" />
                                                )}
                                            </div>
                                        </div>
                                        {/* Contact Details */}
                                        <h2 className="py-10">Contact Details</h2>
                                        <div className="flex flex-wrap">
                                            <div className="input-container">
                                                <CustomText label="Title" name="contact_title" type="text" />
                                                <CustomText label="Email" name="contact_email" type="email" required />
                                                <CustomText label="Position (designation)" name="contact_position" type="text" />
                                            </div>
                                            <div className="input-container">
                                                <CustomText label="Initials" name="contact_initials" type="text" />
                                                <CustomText label="ID/passport number" name="contact_id_number" type="text" />
                                                <CustomText label="Address/branch" name="contact_address" type="text" />
                                            </div>
                                            <div className="input-container">
                                                <CustomText label="First name(s)" name="contact_first_name" type="text" />
                                                <CustomText label="Work tel" name="contact_work_tel" type="text" />
                                                <Field
                                                    label="Preferred Communication"
                                                    options={lookups.preferredCommunication}
                                                    name='contact_preferred_communication'
                                                    component={CustomSelect}
                                                />
                                            </div>
                                            <div className="input-container">
                                                <CustomText label="Surname" name="contact_surname" type="text" />
                                                <CustomText label="Cellphone" name="contact_cellphone" type="text" required/>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Tab 3 - General Material */}
                                    <div className={classNames(openTab === 3 ? "block" : "hidden")} id="general_material_disclosures">
                                        <h2>General Material Disclosures</h2>
                                        <div className="flex flex-wrap">
                                            <div className="input-container">
                                                <Field
                                                    label="Has any insurer ever declined your proposal"
                                                    options={lookups.yesno}
                                                    name='general_insurer_declined'
                                                    component={CustomSelect}
                                                />
                                                {values.general_insurer_declined === 1 && values.general_insurer_declined !== null && (
                                                    <>
                                                        <CustomText label="Policy Number(s)" name="general_insurer_declined_policy" type="text" />
                                                        <CustomTextArea label="Reason(s)" name="general_insurer_declined_reasons" />
                                                    </>
                                                )}
                                                <Field
                                                    label="Main areas of operation/routes"
                                                    options={lookups.provinces}
                                                    name='general_area_operation'
                                                    component={CustomSelect}
                                                    multi
                                                />
                                            </div>
                                            <div className="input-container">
                                                <Field
                                                    label="Has a insurer ever cancelled/refused to renew a policy"
                                                    options={lookups.yesno}
                                                    name='general_insurer_cancelled'
                                                    component={CustomSelect}
                                                />
                                                {values.general_insurer_cancelled === 1 && values.general_insurer_cancelled !== null && (
                                                    <>
                                                        <CustomText label="Policy Number(s)" name="general_insurer_cancelled_policy" type="text" />
                                                        <CustomTextArea label="Reason(s)" name="general_insurer_cancelled_reasons"  />
                                                    </>
                                                )}
                                                <Field
                                                    label="Daily operating hours?"
                                                    options={lookups.operatingHours}
                                                    name='general_operating_hours'
                                                    component={CustomSelect}
                                                />
                                            </div>
                                            <div className="input-container">
                                                <Field
                                                    label="Reasons for requesting a quote"
                                                    options={lookups.reasonRequestingQuote}
                                                    name='general_reason_requesting'
                                                    component={CustomSelect}
                                                />
                                                <Field
                                                    label="Foreign national drivers"
                                                    options={lookups.yesno}
                                                    name='general_foreign_drivers'
                                                    component={CustomSelect}
                                                />
                                            </div>
                                            <div className="input-container">
                                                <Field
                                                    label="Underwriting disclosures"
                                                    options={lookups.underwriting}
                                                    name='general_underwriting'
                                                    component={CustomSelect}
                                                    multi
                                                />
                                                {values.general_underwriting !== null && values.general_underwriting !== undefined && values.general_underwriting.includes('3') && (
                                                    <Field
                                                        label="Cross border areas"
                                                        options={lookups.countries}
                                                        name='general_cross_border_areas'
                                                        component={CustomSelect}
                                                        multi
                                                    />
                                                )}
                                                <Field
                                                    label="Telematics installed"
                                                    options={lookups.yesnoReplaceYes}
                                                    name='general_telematics'
                                                    component={CustomSelect}
                                                />
                                                {values.general_telematics === 1 && (
                                                    <Field
                                                        label="Detail any measures in place that can prevent any claims"
                                                        options={lookups.telematics}
                                                        name='general_telematics_details'
                                                        component={CustomSelect}
                                                        multi
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Tab 4 - Vehicle Details */}
                                    <div className={openTab === 4 ? "block" : "hidden"} id="vehicle_details">
                                        <h2>Vehicle Details</h2>
                                        <div className="flex flex-wrap">
                                            <div className="input-container">
                                                <Field
                                                    label="What security measures are used"
                                                    options={lookups.sercurityMeasures}
                                                    name='vehicles_security'
                                                    component={CustomSelect}
                                                    multi
                                                />
                                            </div>
                                            <div className="input-container">
                                                <Field
                                                    label="Where are the vehicles parked on route"
                                                    options={lookups.vehiclesParked}
                                                    name='vehicles_parked'
                                                    component={CustomSelect}
                                                />
                                                {values.vehicles_parked !== 1 && (
                                                    <CustomText label="Supply Details" name="vehicles_parked_details" type="text" />
                                                )}
                                            </div>
                                            <div className="input-container">
                                                <Field
                                                    label="Who will the truck be driven by?"
                                                    options={lookups.truckDrivers}
                                                    name='vehicles_driven_by'
                                                    component={CustomSelect}
                                                    multi
                                                />
                                            </div>
                                        </div>
                                        <FieldArray name="drivers">
                                            {({ push, remove }) => (
                                                <>
                                                    <div className="flex flex-col pb-10">
                                                        {values.drivers !== undefined && values.drivers.length > 0 && (
                                                            <div className="-my-2 overflow-x-auto sm:-mx-2 lg:-mx-4">
                                                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                                    <div className="overflow-hidden border-b shadow border-gray-200">
                                                                        <table className="min-w-full divide-y divide-gray-200">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th
                                                                                        scope="col"
                                                                                        className="pl-2 py-3 text-left"
                                                                                    >
                                                                                        Title
                                                                                    </th>
                                                                                    <th
                                                                                        scope="col"
                                                                                        className=" py-3 text-left font-text-black"
                                                                                    >
                                                                                        First name(s)
                                                                                    </th>
                                                                                    <th
                                                                                        scope="col"
                                                                                        className=" py-3 text-left font-text-black"
                                                                                    >
                                                                                        Surname
                                                                                    </th>
                                                                                    <th
                                                                                        scope="col"
                                                                                        className=" py-3 text-left font-text-black"
                                                                                    >
                                                                                        Email
                                                                                    </th>
                                                                                    <th
                                                                                        scope="col"
                                                                                        className=" py-3 text-left font-text-black"
                                                                                    >
                                                                                        ID number
                                                                                    </th>
                                                                                    <th
                                                                                        scope="col"
                                                                                        className=" py-3 text-left font-text-black"
                                                                                    >
                                                                                        Cellphone
                                                                                    </th>
                                                                                    <th scope="col" className="relative px-6 py-3">
                                                                                        <span className="sr-only">Remove</span>
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {values.drivers.map((d, index) => (
                                                                                    <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                                                                        <td className="pl-2 py-4">
                                                                                            <CustomText name={`drivers[${index}].driver_title`} value={d.driver_title} type="text" size="w-14"/>
                                                                                        </td>
                                                                                        <td className="py-4">
                                                                                            <CustomText name={`drivers[${index}].driver_first_name`} value={d.driver_first_name} type="text" size="lg:w-28 xl:w-40"/>
                                                                                        </td>
                                                                                        <td className="py-4">
                                                                                            <CustomText name={`drivers[${index}].driver_surname`} value={d.driver_surname} type="text" size="lg:w-28 xl:w-40"/>
                                                                                        </td>
                                                                                        <td className="py-4">
                                                                                            <CustomText name={`drivers[${index}].driver_email`} value={d.driver_email} type="text" size="lg:w-28 xl:w-40"/>
                                                                                        </td>
                                                                                        <td className="py-4">
                                                                                            <CustomText name={`drivers[${index}].driver_id_number`} value={d.driver_id_number} type="text" size="lg:w-28 xl:w-32"/>
                                                                                        </td>
                                                                                        <td className="py-4">
                                                                                            <CustomText name={`drivers[${index}].driver_cellphone`} value={d.driver_cellphone} type="text" size="lg:w-28 xl:w-40"/>
                                                                                        </td>
                                                                                        <td className="text-center font-medium">
                                                                                            <FontAwesomeIcon
                                                                                                className="text-red-500 mx-auto mt-2 hover:text-red-700 cursor-pointer"
                                                                                                onClick={() => remove(index)}
                                                                                                icon={faTrashAlt}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="button blue"
                                                        onClick={() => push({
                                                            driver_title: "", driver_first_name: "", driver_surname: "",
                                                            driver_cellphone: "", driver_email: "", driver_id_number: ""
                                                        })}
                                                    >
                                                        <span><FontAwesomeIcon icon={faPlusCircle}/>&nbsp;Add a Driver</span>
                                                    </button>
                                                    <Field name="drivers" component={ExcelImport}/>
                                                    <a href="/assets/documents/Driver Example.xlsx" download className="button blue" ><span><FontAwesomeIcon icon={faFileDownload}/>&nbsp;Download Example</span></a>
                                                </>
                                            )}
                                        </FieldArray>
                                    </div>                                    
                                    {/* Claims History */}
                                    <div className={classNames(openTab === 5 ? "block" : "hidden", "overflow-y-auto h-screen")} id="claims_history">
                                        <h2>Claims History</h2>
                                        <p className="pb-2 text-gray-800 text-sm">Please advise details of all losses during last 3 years , for the entities under the following headings</p>
                                        <FieldArray name="claims">
                                            {({ push, remove }) => (
                                                <>
                                                    <div className="flex flex-col pb-10">
                                                        {values.claims !== undefined && values.claims.length > 0 && (
                                                            <div className="-my-2 overflow-x-auto sm:-mx-2 lg:-mx-4">
                                                                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                                    <div className="overflow-hidden border-b shadow border-gray-200">
                                                                        <table className="min-w-full divide-y divide-gray-200">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th
                                                                                        scope="col"
                                                                                        className="pl-2 py-3 text-left"
                                                                                    >
                                                                                        Date of loss
                                                                                    </th>
                                                                                    <th
                                                                                        scope="col"
                                                                                        className=" py-3 text-left font-text-black"
                                                                                    >
                                                                                        Vehicle type
                                                                                    </th>
                                                                                    <th
                                                                                        scope="col"
                                                                                        className=" py-3 text-left font-text-black"
                                                                                    >
                                                                                        Vehicle sum insured
                                                                                    </th>
                                                                                    <th
                                                                                        scope="col"
                                                                                        className=" py-3 text-left font-text-black"
                                                                                    >
                                                                                        Claims description
                                                                                    </th>
                                                                                    <th
                                                                                        scope="col"
                                                                                        className=" py-3 text-left font-text-black"
                                                                                    >
                                                                                        Gross claims
                                                                                    </th>
                                                                                    <th
                                                                                        scope="col"
                                                                                        className=" py-3 text-left font-text-black"
                                                                                    >
                                                                                        Nett claims
                                                                                    </th>
                                                                                    <th scope="col" className="relative px-6 py-3">
                                                                                        <span className="sr-only">Remove</span>
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {values.claims.map((d, index) => (
                                                                                    <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                                                                        <td className="pl-2 py-4">
                                                                                            <CustomText name={`claims[${index}].claim_date_of_loss`} value={d.claim_date_of_loss} type="date" size="lg:w-28 xl:w-40"/>
                                                                                        </td>
                                                                                        <td className="py-4">
                                                                                            <CustomText name={`claims[${index}].claim_vehicle_type`} value={d.claim_vehicle_type} type="text" size="lg:w-28 xl:w-40"/>
                                                                                        </td>
                                                                                        <td className="py-4">
                                                                                            <CustomText name={`claims[${index}].claim_vehicle_sum_insured`} value={d.claim_vehicle_sum_insured} type="number" prefix="R" size="lg:w-28 xl:w-40"/>
                                                                                        </td>
                                                                                        <td className="py-4">
                                                                                            <CustomTextArea name={`claims[${index}].claim_description`} value={d.claim_description} size="lg:w-28 xl:w-40"/>
                                                                                        </td>
                                                                                        <td className="py-4">
                                                                                            <CustomText name={`claims[${index}].claim_gross`} value={d.claim_gross} type="number" prefix="R" size="lg:w-28 xl:w-40"/>
                                                                                        </td>
                                                                                        <td className="py-4">
                                                                                            <CustomText name={`claims[${index}].claim_nett`} value={d.claim_nett} type="number" prefix="R" size="lg:w-28 xl:w-40"/>
                                                                                        </td>
                                                                                        <td className="text-center font-medium">
                                                                                            <FontAwesomeIcon className="text-red-500 mx-auto mt-2 hover:text-red-700 cursor-pointer" onClick={() => remove(index)} icon={faTrashAlt}/>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="button blue"
                                                        onClick={() => push({
                                                            claim_date_of_loss: "", claim_vehicle_type: "", claim_vehicle_sum_insured: "",
                                                            claim_description: "", claim_gross: "", claim_nett: ""
                                                        })}
                                                    >
                                                        <span><FontAwesomeIcon icon={faPlusCircle}/>&nbsp;Add a Claim</span>                                            
                                                    </button>
                                                    <Field name="claims" component={ExcelImport}/>
                                                    <a href="/assets/documents/Claim Example.xlsx" download className="button blue" ><span><FontAwesomeIcon icon={faFileDownload}/>&nbsp;Download Example</span></a>
                                                </>
                                            )}
                                        </FieldArray>
                                    </div>
                                    {/* Vehicles */}
                                    <div className={classNames(openTab === 6 ? "block" : "hidden", "overflow-y-auto h-screen")} id="vehicles">
                                        {/* Moto Dropdowns */}
                                        <div className="pb-5 flex flex-wrap">
                                            <div className="input-container">
                                                <Field
                                                    label="Motor insurance requested"
                                                    options={lookups.motorInsurance}
                                                    name='motor_insurance_requested'
                                                    component={CustomSelect}
                                                />
                                            </div>
                                            <div className="input-container">
                                                <Field
                                                    label="Motor extensions required"
                                                    options={lookups.motorExtensions}
                                                    name='motor_extensions_requested'
                                                    component={CustomSelect}
                                                    multi
                                                />
                                            </div>
                                        </div>
                                        {/* Vehicle details */}
                                        <br className="py-10" />
                                        <FieldArray name="vehicles" >
                                            {({ push, remove }) => (
                                                <>
                                                    <div className="flex flex-col pb-10">
                                                        {values.vehicles !== undefined && values.vehicles.length > 0 && (
                                                            <div className="-my-2 overflow-x-auto -mx-0">
                                                                <div className="py-2 align-middle inline-block min-w-full sm:px-2 lg:px-4">
                                                                    <div className="pb-24 overflow-hidden border-b shadow border-gray-200">
                                                                        <table className="min-w-full divide-y divide-gray-200">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th
                                                                                        scope="col"
                                                                                        className="pl-2 py-3 text-left"
                                                                                    >
                                                                                        Make
                                                                                    </th>
                                                                                    <th
                                                                                        scope="col"
                                                                                        className="py-3 text-left font-text-black"
                                                                                    >
                                                                                        Model
                                                                                    </th>
                                                                                    <th
                                                                                        scope="col"
                                                                                        className=" py-3 text-left font-text-black"
                                                                                    >
                                                                                        Year
                                                                                    </th>
                                                                                    <th
                                                                                        scope="col"
                                                                                        className=" py-3 text-left font-text-black"
                                                                                    >
                                                                                        Vin
                                                                                    </th>
                                                                                    <th
                                                                                        scope="col"
                                                                                        className=" py-3 text-left font-text-black"
                                                                                    >
                                                                                        Registration
                                                                                    </th>
                                                                                    <th
                                                                                        scope="col"
                                                                                        className=" py-3 text-left font-text-black"
                                                                                    >
                                                                                        Cover required
                                                                                    </th>
                                                                                    <th
                                                                                        scope="col"
                                                                                        className=" py-3 text-left font-text-black"
                                                                                    >
                                                                                        Value
                                                                                    </th>
                                                                                    <th
                                                                                        scope="col"
                                                                                        className=" py-3 text-left font-text-black"
                                                                                    >
                                                                                        GIT limit
                                                                                    </th>
                                                                                    <th
                                                                                        scope="col"
                                                                                        className=" py-3 text-left font-text-black"
                                                                                    >
                                                                                        GIT cover
                                                                                    </th>
                                                                                    <th
                                                                                        scope="col"
                                                                                        className=" py-3 text-left font-text-black"
                                                                                    >
                                                                                        Registered owner/holder
                                                                                    </th>
                                                                                    <th scope="col" className="relative px-6 py-3">
                                                                                        <span className="sr-only">Remove</span>
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {values.vehicles !== null && values.vehicles.map((d, index) => (
                                                                                    <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                                                                        <td className="pl-2 py-4">
                                                                                            <CustomText name={`vehicles[${index}].vehicle_make`} value={d.vehicle_make} type="text" size="lg:w-16 xl:w-24"/>
                                                                                        </td>
                                                                                        <td className="py-4">
                                                                                            <CustomText name={`vehicles[${index}].vehicle_model`} value={d.vehicle_model} type="text" size="lg:w-16 xl:w-24"/>
                                                                                        </td>
                                                                                        <td className="py-4">
                                                                                            <CustomText name={`vehicles[${index}].vehicle_year`} value={d.vehicle_year} type="text" size="lg:w-16 xl:w-24"/>
                                                                                        </td>
                                                                                        <td className="py-4">
                                                                                            <CustomText name={`vehicles[${index}].vehicle_insurance_number`} value={d.vehicle_insurance_number} type="text" size="lg:w-16 xl:w-24"/>
                                                                                        </td>
                                                                                        <td className="py-4">
                                                                                            <CustomText name={`vehicles[${index}].vehicle_registration`} value={d.vehicle_registration} type="text" size="lg:w-16 xl:w-24"/>
                                                                                        </td>
                                                                                        <td className="py-4">
                                                                                            <Field
                                                                                                options={lookups.yesno}
                                                                                                name={`vehicles[${index}].vehicle_cover`}
                                                                                                component={CustomSelect}
                                                                                                size="w-40"
                                                                                            />
                                                                                        </td>
                                                                                        <td className="py-4">
                                                                                            <CustomText name={`vehicles[${index}].vehicle_value`} type="number" value={d.vehicle_value} prefix="R" size="lg:w-20 xl:w-32"/>
                                                                                        </td>
                                                                                        <td className="py-4">
                                                                                            <CustomText name={`vehicles[${index}].vehicle_git_limit`} type="number" value={d.vehicle_git_limit} prefix="R" size="lg:w-20 xl:w-32"/>
                                                                                        </td>
                                                                                        <td className="py-4">
                                                                                            <CustomText name={`vehicles[${index}].vehicle_git_cover`} type="number" value={d.vehicle_git_cover} prefix="R" size="lg:w-20 xl:w-32"/>
                                                                                        </td>
                                                                                        <td className="py-4">
                                                                                            <Field
                                                                                                options={lookups.vehiclesOwner}
                                                                                                name={`vehicles[${index}].vehicle_owner`}
                                                                                                component={CustomSelect}
                                                                                                size="w-40"
                                                                                            />
                                                                                        </td>
                                                                                        <td className="py-4">
                                                                                            <FontAwesomeIcon className="text-red-500 mx-auto mt-2 hover:text-red-700 cursor-pointer" onClick={() => remove(index)} icon={faTrashAlt}/>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="button blue"
                                                        onClick={() => push({
                                                            vehicle_make: "", vehicle_model: "", vehicle_year: "",
                                                            vehicle_insurance_number: "", vehicle_registration: "", vehicle_cover: "",
                                                            vehicle_value: "", vehicle_git_limit: "", vehicle_git_cover: ""
                                                        })}
                                                    >
                                                        <span><FontAwesomeIcon icon={faPlusCircle}/>&nbsp;Add a Vehicle</span>
                                                    </button>
                                                    <Field name="vehicles" component={ExcelImport}/>
                                                    <a href="/assets/documents/Vehicle Example.xlsx" download className="button blue" ><span><FontAwesomeIcon icon={faFileDownload}/>&nbsp;Download Example</span></a>
                                                </>
                                            )}
                                        </FieldArray>
                                        <div className="flex flex-wrap">
                                            <div className="input-container">
                                                <Field
                                                    label="Any vehicles registered in any name other than the proposer                                    "
                                                    options={lookups.yesnoReplaceYes}
                                                    name='vehicles_registered_name'
                                                    component={CustomSelect}
                                                />
                                                {values.vehicles_registered_name === 1 && (
                                                    <>
                                                        <CustomTextArea label="Please provide the name of the registered owner and the nature of the relationship to the proposer." name="vehicles_registered_name_details" />
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>                                    
                                    {/* GIT */}
                                    <div className={classNames(openTab === 7 ? "block" : "hidden", "overflow-y-auto h-screen")} id="git_details">
                                        <h2>GIT Details</h2>
                                        <div className="flex flex-wrap">
                                            <div className="input-container">
                                                <Field
                                                    label="GIT insurance"
                                                    options={lookups.gitInsurance}
                                                    name='git_insurance'
                                                    required
                                                    component={CustomSelect}
                                                />
                                                <Field
                                                    label="Goods Carried"
                                                    name="git_goods_carried"
                                                    options={lookups.goodsCarried}
                                                    component={CustomSelect}
                                                    multi
                                                />
                                            </div>
                                            <div className="input-container">
                                                <Field
                                                    label="Do you have loads carried by sub-contractors"
                                                    options={lookups.yesnoReplaceYes}
                                                    name='git_sub_contractor'
                                                    component={CustomSelect}
                                                />
                                                {values.git_sub_contractor === 1 && (
                                                    <CustomText label="Name(s)" name="git_sub_contractor_details" type="text" />
                                                )}
                                            </div>
                                            <div className="input-container">
                                                <CustomText label="Maximum load limit required" name="git_load" type="number" prefix="R"/>
                                                <Field
                                                    label="Do you require GIT contingency cover"
                                                    options={lookups.yesnoReplaceYes}
                                                    name='git_cover'
                                                    component={CustomSelect}
                                                />
                                                {values.git_cover === 1 && (
                                                    <>
                                                        <CustomText label="Maximum annual haulage fee" name="git_cover_haulage_fee" type="number" prefix="R"/>
                                                    </>
                                                )}
                                            </div>
                                            <div className="input-container">
                                                <Field
                                                    label="GIT Extensions"
                                                    options={lookups.gitExtensions}
                                                    name='git_extensions'
                                                    component={CustomSelect}
                                                    multi
                                                />
                                            </div>
                                        </div>
                                        {values.git_cover === 1 && values.git_cover !== undefined && (
                                            <>
                                                {/* Sub-Contractor Section */}
                                                <p className="pb-2 text-gray-800 text-sm">Please add any sub crontactors that you use</p>
                                                <p className="pb-2 italic font-bold text-sm">Warranty - Cover only applies when Sub-contractors own insurance fails to settle a claim. Confirm that you check on a monthly basis that GIT cover is in place for same risks and limits as your own GIT insurance.</p>
                                                <FieldArray name="sub_contractors">
                                                    {({ push, remove }) => (
                                                        <>
                                                            <div className="flex flex-col pb-10">
                                                            {values.sub_contractors !== undefined && values.sub_contractors.length > 0 && (
                                                                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                                        <div className="overflow-hidden border-b shadow border-gray-200">
                                                                            <table className="min-w-full divide-y divide-gray-200">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th
                                                                                            scope="col"
                                                                                            className="pl-2 py-3 text-left"
                                                                                        >
                                                                                            Sub-contractors name
                                                                                        </th>
                                                                                        <th
                                                                                            scope="col"
                                                                                            className=" py-3 text-left font-text-black"
                                                                                        >
                                                                                            Vehicle registration number
                                                                                        </th>
                                                                                        <th
                                                                                            scope="col"
                                                                                            className=" py-3 text-left font-text-black"
                                                                                        >
                                                                                            Revenue generated on average per month
                                                                                        </th>
                                                                                        <th
                                                                                            scope="col"
                                                                                            className=" py-3 text-left font-text-black"
                                                                                        >
                                                                                            Load Limit
                                                                                        </th>
                                                                                        <th scope="col" className="relative px-6 py-3">
                                                                                            <span className="sr-only">Remove</span>
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {values.sub_contractors.map((d, index) => (
                                                                                    <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                                                                        <td className="pl-2 py-4">
                                                                                            <CustomText name={`sub_contractors[${index}].sub_contractors_name`} value={d.sub_contractors_name} type="text" size="lg:w-28 xl:w-40"/>
                                                                                        </td>
                                                                                        <td className="pl-2 py-4">
                                                                                            <CustomText name={`sub_contractors[${index}].sub_contractors_registration`} value={d.sub_contractors_registration} type="text" size="lg:w-28 xl:w-40"/>
                                                                                        </td>
                                                                                        <td className="pl-2 py-4">
                                                                                            <CustomText name={`sub_contractors[${index}].sub_contractors_revenue`} value={d.sub_contractors_revenue} type="number" prefix="R" size="lg:w-28 xl:w-40"/>
                                                                                        </td>
                                                                                        <td className="pl-2 py-4">
                                                                                            <CustomText name={`sub_contractors[${index}].sub_contractors_load`} value={d.sub_contractors_load} type="text" size="lg:w-28 xl:w-40"/>
                                                                                        </td>
                                                                                        <td className="text-center font-medium">
                                                                                            <FontAwesomeIcon className="text-red-500 mx-auto mt-2 hover:text-red-700 cursor-pointer" onClick={() => remove(index)} icon={faTrashAlt}/>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            </div>
                                                            <button
                                                                type="button"
                                                                className="button blue"
                                                                onClick={() => push({
                                                                    sub_contractors_name: "", sub_contractors_registration: "",
                                                                    sub_contractors_load: "", sub_contractors_revenue: ""
                                                                })}
                                                            >                                                    
                                                                <span><FontAwesomeIcon icon={faPlusCircle}/>&nbsp;Add a sub contractor</span>
                                                            </button>
                                                            <Field name="sub_contractors" component={ExcelImport}/>
                                                            <a href="/assets/documents/Sub Contractor Example.xlsx" download className="button blue" ><span><FontAwesomeIcon icon={faFileDownload}/>&nbsp;Download Example</span></a>
                                                        </>
                                                    )}
                                                </FieldArray>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
