import React, { useEffect, useState, useMemo } from "react";
import axios from "axios";
import QuoteTable from "../tables/QuoteTable";
import LoadingComponent from "../layout/LoadingComponent";
import { format } from 'date-fns'

export default function ShortQuotesView() {
    const [data, setData] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (
            async () => {
                const {data} = await axios.get(process.env.REACT_APP_API_URL + "quotes", {});
                setData(data);
                setLoading(false);
            }
        )();
    }, []);
    
    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
                show: false,
            },
            {
                Header: "Date / Time",
                accessor: "created_at",  
                Cell: ({ value}) => { return format(new Date(value), 'dd MMMM yyyy / HH:mm')}              
            },
            {
                Header: "Company",
                accessor: "company_name",
            },
            {
                Header: "Contact Person",
                accessor: "contact_person",
            },
            {
                Header: "Email",
                accessor: "contact_email",
            },
            {
                Header: "Consultant",
                accessor: "added_by",
            },
        ],
        []
    );

    if (loading === true) {
        return <LoadingComponent />
    }

    return (
        <div className="block-container w-11/12">
            {data && (
                <QuoteTable 
                    columns={columns} 
                    data={data} 
                    linkTo={"/quotes/edit"}
                />
            )}
        </div>
    );
}
