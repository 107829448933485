import React, { useEffect, useState } from "react";
import { openModal } from "../../app/common/modals/modalReducer";
import { authorizeProposalForm } from "../auth/authActions";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form} from "formik";
import * as Yup from 'yup';
import LoadingComponent from "../layout/LoadingComponent";
import {  faHandHoldingMedical,faSearch} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactHtmlParser from 'react-html-parser';

const navigation = [
    // { name: 'Overview', href: '#overview', icon: faHouse, current: true, count: 1 },
    { name: 'Scheme Selection', href: '#scheme', icon: faHandHoldingMedical, current: true, count: 1 },
    // { name: 'Settings', href: '#settings', icon: faCog, current: false, count: 3 },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function GapQuotesEdit(props) {
    const dispatch = useDispatch();
    const [openTab, setOpenTab] = React.useState(1);
    const quoteID = props.match.params.id;
    const { proposalForm } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(true);
    const [lookups, setLookups] = useState([]);
    const [schemes, setSchemes] = useState([]);
    const [optionsByScheme, setOptionsByScheme] = useState([]);
    const [selectedSchemes, setSelectedSchemes] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [schemeSearch, setSchemeSearch] = useState("");
    const [optionSearch, setOptionSearch] = useState("");

    useEffect(() => {
        (
            async () => {
                // prevent infinite loop
                if (loading) {
                    const {data} = await axios.get(process.env.REACT_APP_API_URL + "gap-quotes/" + quoteID, {});
                    dispatch(authorizeProposalForm(data));

                    setSchemes(data.schemes);
                    setOptionsByScheme(data.optionsByScheme);

                    // go through the quotes that are in the data and get the selected schemes to precheck rows
                    if (data.quote) {
                        const quoteData = data.quote[0].get_quotes_schemes;
                        let selectedSchemes = [];
                        let selectedOptions = [];
                        quoteData.forEach(row => {
                            selectedSchemes.push(row.scheme_id);
                            selectedOptions.push(row.option_id);
                        });

                        setSelectedSchemes(selectedSchemes);
                        setSelectedOptions(selectedOptions);
                    }

                    // // testing
                    // schemes.filter((scheme) => {
                    //     if (selectedSchemes.includes(scheme.id))
                    //     console.log(scheme.scheme_name);
                    // })

                    const lookupsResponse = await axios.get(process.env.REACT_APP_API_URL + "lookups", {});
                    setLookups(lookupsResponse.data);
                }

                setLoading(false);
            }
        )();
    });

    if (loading === true) {
        return <LoadingComponent />
    }

    return (
        <Formik
            initialValues={proposalForm}
            enableReinitialize="true"
            validationSchema={Yup.object({
                surname: Yup.string().required('The company name is required'),
                email: Yup.string()
                                .required('The email is required')
                                .email('The email must be a valid email'),
            })}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
                // information for the quote
                const formData = {
                    company_name:                       values.company_name,
                    company_vat_number:                 values.company_vat_number,
                    company_physical_address:           values.company_physical_address,
                    company_trading_name:               values.company_trading_name,
                    company_telephone:                  values.company_telephone,
                    company_postal_address:             values.company_postal_address,
                    company_registration_number:        values.company_registration_number,
                    company_email:                      values.company_email,
                    company_description:                values.company_description,
                    company_type:                       values.company_type,
                    company_depos:                      values.company_depos,
                    company_other_trading_name:         values.company_other_trading_name,

                    contact_preferred_language:         values.contact_preferred_language,
                    contact_title:                      values.contact_title,
                    contact_email:                      values.contact_email,
                    contact_position:                   values.contact_position,
                    contact_initials:                   values.contact_initials,
                    contact_id_number:                  values.contact_id_number,
                    contact_address:                    values.contact_address,
                    contact_first_name:                 values.contact_first_name,
                    contact_work_tel:                   values.contact_work_tel,
                    contact_preferred_communication:    values.contact_preferred_communication,
                    contact_surname:                    values.contact_surname,
                    contact_cellphone:                  values.contact_cellphone,

                    general_insurer_declined:           values.general_insurer_declined,
                    general_insurer_declined_policy:    values.general_insurer_declined_policy,
                    general_insurer_declined_reasons:   values.general_insurer_declined_reasons,
                    general_area_operation:             values.general_area_operation,
                    general_insurer_cancelled:          values.general_insurer_cancelled,
                    general_insurer_cancelled_policy:   values.general_insurer_cancelled_policy,
                    general_insurer_cancelled_reasons:  values.general_insurer_cancelled_reasons,
                    general_operating_hours:            values.general_operating_hours,
                    general_reason_requesting:          values.general_reason_requesting,
                    general_foreign_drivers:            values.general_foreign_drivers,
                    general_underwriting:               values.general_underwriting,
                    general_cross_border_areas:         values.general_cross_border_areas,
                    general_telematics:                 values.general_telematics,

                    vehicles_security:                  values.vehicles_security,
                    vehicles_parked:                    values.vehicles_parked,
                    vehicles_driven_by:                 values.vehicles_driven_by,
                    vehicles_registered_name:           values.vehicles_registered_name,

                    motor_insurance_requested:          values.motor_insurance_requested,
                    motor_extensions_requested:         values.motor_extensions_requested,

                    git_insurance:                      values.git_insurance,
                    git_goods_carried:                  values.git_goods_carried,
                    git_sub_contractor:                 values.git_sub_contractor,
                    git_cover:                          values.git_cover,
                    git_extensions:                     values.git_extensions,

                    territorial_limits:                 values.territorial_limits,

                    // Arrays
                    drivers:                            values.drivers,
                    claims:                             values.claims,
                    vehicles:                           values.vehicles,
                    sub_contractors:                    values.sub_contractors,
                }

                try {
                    await axios.put(process.env.REACT_APP_API_URL + "gap-quotes/" + quoteID, formData)
                    .then(() => {
                        dispatch(openModal({modalType: "ModalNotification", modalProps: {
                            type: 'success',
                            heading: 'Success',
                            body: 'The data has been updated successfully',
                            closeText: 'Close',
                        }}));
                    });
                } catch (error) {
                    dispatch(openModal({modalType: "ModalNotification", modalProps: {
                        type: 'error',
                        heading: 'Something went wrong',
                        body: 'Please try again',
                        closeText: 'Close',
                    }}))
                }
            }}
        >
            {({isSubmitting, errors, values}) => (
                <Form>
                    <div className="flex">
                        {/* Tabs */}
                        <nav className="space-y-1" aria-label="Sidebar">
                            {navigation.map((item) => (
                                <a
                                    key={item.name}
                                    onClick={e => {
                                        e.preventDefault();
                                        setOpenTab(item.count);
                                    }}
                                    href={item.href}
                                    className={classNames(
                                        openTab === item.count ? 'bg-blue-comparemed-dark text-white' : 'text-blue-comparemed-dark hover:bg-blue-comparemed-default hover:text-white',
                                        'group flex items-center px-3 py-2 text-sm font-medium'
                                    )}
                                    role="tablist"
                                    data-toggle="tab"
                                >
                                    {/* Get font awesome icons aligned with the buttons then finish up with displaying the information on the left nav bar */}
                                    <FontAwesomeIcon
                                        className={classNames(
                                            openTab === item.count ? 'text-white' : 'hover:bg-blue-comparemed-default hover:text-white',
                                            'flex-shrink-0 -ml-1 mr-3 h-6 w-8'
                                        )}
                                        aria-hidden="true"
                                        icon={item.icon}
                                    />
                                    <span className="truncate">{item.name}</span>
                                </a>
                            ))}

                            <div className="mt-8">
                                <h3 className="px-3 py-5 text-xs font-semibold text-gray-500 uppercase tracking-wider" id="projects-headline">
                                    Quick Actions
                                </h3>
                            </div>
                        </nav>
                        <div className="block-container h-auto w-5/6">
                            <div className="flex-auto">
                                <div className="tab-content tab-space">
                                    <div className={openTab === 1 ? "block" : "hidden"} id="scheme">
                                        <div className="flex flex-wrap justify-between">
                                            <div className="w-1/3">
                                                <div className="text-center border-2 px-4 py-2 flex-row content-center ">
                                                    <h2 className="text-center pl-5">Insurer <span>(4 options max, 4 options selected)</span></h2>
                                                    {/* Search */}
                                                    <div className="mt-1 relative shadow-sm w-40 m-auto ">
                                                        <input
                                                            type="text"
                                                            placeholder="Search"
                                                            className={`py-2 px-3 shadow-sm block border rounded-md focus:ring-blue-500 focus:border-blue-500 border-gray-400 pl-8 sm:pl-6`}

                                                            onChange={event => {setSchemeSearch(event.target.value)}}
                                                        />
                                                        <div className="absolute inset-y-0 right-0 pr-0 flex items-center pointer-events-none">
                                                        <FontAwesomeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" icon={faSearch}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* Schemes */}
                                                <ul className="mt-3 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
                                                    {schemes.filter((val) => {
                                                        if (schemeSearch === "") {
                                                            return val
                                                        } else if (val.scheme_name.toLowerCase().includes(schemeSearch.toLowerCase())) {
                                                            return val
                                                        }
                                                    }).map((scheme) => (
                                                        <li key={scheme.id} className="col-span-1 flex shadow-sm rounded-md h-10">
                                                            <div className="flex-1 flex items-center justify-between truncate ml-2 pl-2">
                                                                <div className="flex-shrink-0 pl-2">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                                        name="schemes"
                                                                    />
                                                                </div>
                                                                <div className="flex-1 px-4 py-2 text-sm truncate">
                                                                    {scheme['scheme_name']}
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div className="w-2/3">
                                                <div className="text-center border-2 px-4 py-2 flex-row content-center ">
                                                    <h2 className="text-center">Option and Monthly Premium <span>(4 options max, 4 options selected)</span></h2>
                                                    {/* Search */}
                                                    <div className="mt-1 relative shadow-sm w-40 m-auto ">
                                                        <input
                                                            type="text"
                                                            placeholder="Search"
                                                            className={`py-2 px-3 shadow-sm block border rounded-md focus:ring-blue-500 focus:border-blue-500 border-gray-400 pl-8 sm:pl-6`}
                                                            onChange={event => {setOptionSearch(event.target.value)}}
                                                        />
                                                        <div className="absolute inset-y-0 right-0 pr-0 flex items-center pointer-events-none">
                                                            <FontAwesomeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" icon={faSearch}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* options */}
                                                <div className="gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
                                                    {/* search/filter for options  */}
                                                    {optionsByScheme.filter((option) => {
                                                        if (selectedSchemes.includes(option.scheme_id))
                                                            return option;
                                                    }).map((scheme) => (
                                                        <div key={scheme.id}>
                                                            <h1 className="text-center bg-blue-comparemed-default py-2 text-white">{scheme.scheme_name}</h1>
                                                            {/* {console.log(scheme.options)} */}
                                                            {scheme.options.filter((option) => {
                                                                if (optionSearch === "") {
                                                                    return option
                                                                } else if (option.option_name.toLowerCase().includes(optionSearch.toLowerCase())) {
                                                                    return option
                                                                }
                                                            }).map((option) => (
                                                                option.data.map(optionData => (
                                                                    <div 
                                                                        key={optionData.data_id} 
                                                                        className="px-2 py-2 flex items-center space-x-3"
                                                                    >
                                                                        <div className="flex-shrink-0 pl-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                                                name="schemes"
                                                                            />
                                                                        </div>
                                                                        <div className="flex-1 min-w-0">
                                                                            <p className="font-bold">{option.option_name} ({optionData.year}{optionData.active === 0 ? ' INACTIVE' : ''})</p>
                                                                            <p className="text-gray-500 truncate" >{ReactHtmlParser(optionData.meta_value)}</p>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            ))}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
