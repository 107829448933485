import React, { useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useTable, useGlobalFilter, useAsyncDebounce, useSortBy, usePagination } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDoubleLeft, faChevronDoubleRight, faChevronLeft, faChevronRight, faSort, faSortDown, faSortUp } from "@fortawesome/pro-solid-svg-icons";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

// Define a default UI for filtering
function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 1000);

    return (
        <div className="ui icon input" style={{ marginTop: -10 }}>
            <input
                type="text"
                name="filterValue"
                value={value || ""}
                placeholder={`Filter ${count} results`}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
            />
            <i className="search icon" />
        </div>
    );
}


export default function QuoteTable(props) {
    // get props that is passed to this component

    let history = useHistory();
    const data = useMemo(() => (props.data ? props.data : []), [props.data]);
    const columns = props.columns;

    const globalFilter = useMemo(() => "", []);

    const {
        getTableProps, getTableBodyProps, headerGroups, page, canPreviousPage,
        canNextPage, pageOptions, pageCount, gotoPage, nextPage, previousPage, setPageSize,
        state: { pageIndex, pageSize },
        prepareRow, preGlobalFilteredRows, setGlobalFilter }
    = useTable(
        { columns, data, globalFilter, initialState: { hiddenColumns: ["id"] } },
        useGlobalFilter, useSortBy, usePagination
    );

    return (
        <>
            {data.length > 0 ? (
                <>
                    <div className="flex-1 flex items-center justify-between">
                        <div>
                            Show {' '}
                            <select
                                value={pageSize}
                                onChange={e => {
                                    setPageSize(Number(e.target.value))
                                }}
                            >
                                {[10, 20, 30, 40, 50].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                    </option>
                                ))}
                            </select>  
                            {' '}entries                          
                        </div>
                        <GlobalFilter 
                        setClassName='py-2 px-3 shadow-sm w-full block sm:text-sm border rounded-md focus:ring-blue-500 focus:border-blue-500 border-gray-400 pl-8 sm:pl-6'
                        preGlobalFilteredRows={preGlobalFilteredRows} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
                    </div>
                    <div className="flex flex-col">
                        <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                    <table {...getTableProps()} className="my-2 min-w-full divide-y border-1 border-gray-800 divide-gray-200 ">
                                        <thead className="bg-gray-50">
                                            {headerGroups.map((headerGroup) => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map((column) => (
                                                        <th
                                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                                            scope="col"
                                                            className="px-6 py-3 text-left font-bold text-gray-800 tracking-wider"
                                                        >
                                                            {column.render("Header")}
                                                            <span>
                                                                {' '}{column.isSorted
                                                                ? column.isSortedDesc
                                                                    ? <FontAwesomeIcon icon={faSortUp} />
                                                                    : <FontAwesomeIcon icon={faSortDown} />
                                                                : <FontAwesomeIcon icon={faSort} />}
                                                            </span>
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                        </thead>
                                        <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                                            {page.map((row) => {
                                                prepareRow(row);
                                                return (
                                                    <tr
                                                        {...row.getRowProps()}
                                                        onClick={() => history.push(props.linkTo + '/' + row.values.id)}
                                                        className="cursor-pointer hover:bg-gray-100"
                                                    >
                                                        {row.cells.map((cell) => {
                                                            return <td className="px-6 py-4 whitespace-nowrap" {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                                        })}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pagination flex-1 flex items-center justify-between">
                        <div>
                            <p className="text-sm text-gray-700">
                                Showing{' '}
                                <span className="font-medium">{pageIndex + 1}</span> 
                                {' '}to{' '}
                                <span className="font-medium">{pageOptions.length}{' '}</span>
                                <span className="font-medium">pages</span>
                            </p>
                        </div>
                        <div>
                            <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                                <button
                                    className={classNames(
                                        canPreviousPage ? "bg-white hover:bg-gray-50" : "bg-gray-300 cursor-auto",
                                        "relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 text-sm font-medium text-gray-500 "
                                    )}
                                    onClick={() => gotoPage(0)} disabled={!canPreviousPage}
                                >
                                    <FontAwesomeIcon icon={faChevronDoubleLeft} className="h-5 w-5" aria-hidden="true" />
                                </button>

                                <button                                    
                                    className={classNames(
                                        canPreviousPage ? "bg-white hover:bg-gray-50" : "bg-gray-300 cursor-auto",
                                        "relative inline-flex items-center px-2 py-2 border border-gray-300 text-sm font-medium text-gray-500 "
                                    )}
                                    onClick={() => previousPage()} disabled={!canPreviousPage}
                                >
                                    <FontAwesomeIcon icon={faChevronLeft} className="h-5 w-5" aria-hidden="true" />
                                </button>
                                <button
                                
                                    className={classNames(
                                        canNextPage ? "bg-white hover:bg-gray-50" : "bg-gray-300 cursor-auto",
                                        "relative inline-flex items-center px-2 py-2 border border-gray-300 text-sm font-medium text-gray-500 "
                                    )}
                                    onClick={() => nextPage()} disabled={!canNextPage}
                                >
                                    <FontAwesomeIcon icon={faChevronRight} className="h-5 w-5" aria-hidden="true" />
                                </button>

                                <button                                
                                    className={classNames(
                                        canNextPage ? "bg-white hover:bg-gray-50" : "bg-gray-300 cursor-auto",
                                        "relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 text-sm font-medium text-gray-500 "
                                    )}
                                    onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}
                                >
                                    <FontAwesomeIcon icon={faChevronDoubleRight} className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </nav>
                        </div>
                    </div>
                </>
            ) : (
                <h1>No Data</h1>
            )}
        </>
    );
}
