import React from "react";
import { Link, useHistory } from "react-router-dom";
import CustomButton from "../../app/common/form/CustomButton";
import axios from "axios";
import CustomText from "../../app/common/form/CustomText";
import { Formik, Form } from "formik";
import * as Yup from 'yup';

export default function LoginForm() {
    let history = useHistory();

    const initialValues = {
        email: "",
        password: "",
    };

    return (        
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <img className="mx-auto h-12 w-auto" src="favicon.png" alt="Workflow" />
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        Sign in to your account
                    </h2>
                </div>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize="true"
                    validationSchema={Yup.object({
                        email: Yup.string().required().email(),
                        password: Yup.string().required(),
                    })}
                    onSubmit={async (values, { setSubmitting, setStatus}) => {
                        try {
                            axios.get('/sanctum/csrf-cookie').then(() => {
                                axios.post("login", {
                                   'email': values.email,
                                   'password': values.password,
                               });
                               
                                history.push('/quotes');
                            });
                        } catch (response) {
                            setStatus({ error: 'Unable to login with the provided credentials.'});
                        }
                    }}
                >
                    {({isSubmitting, status}) => (
                    <Form className="mt-8 space-y-6" action="#" method="POST">
                        <div className="rounded-md shadow-sm -space-y-px">
                            <CustomText name="email" type="email" placeholder="E-Mail" size="full"/>
                            <CustomText name="password" type="password" placeholder="Password" size="full"/>
                        </div>
                        {status && (
                            <p className="text-red-500 mb-5">{status.error}</p>
                        )}
                        <CustomButton
                            disabled={isSubmitting}
                            submitBool={isSubmitting}
                            type="submit"
                            text={"Sign In"}
                        />
                        <Link
                            className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-5 rounded-md"
                            as="a"
                            to="/quotes"
                        >
                            Skip
                        </Link>
                    </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}
