import React from "react";
// import { openModal } from "../../app/common/modals/modalReducer";
import { useHistory } from "react-router-dom";
// import { useDispatch } from "react-redux";
import axios from "axios";

export default function NavBar() {
    // const dispatch = useDispatch();
    let history = useHistory();

    const logout = async () => {
        await axios.post(process.env.REACT_APP_API_URL + 'logout', {});
        history.replace('/');

    }
    
    return (
        <div style={{ height: '60px', marginBottom: '50px' }}>
            <div style={{height:"50px", backgroundColor:"#e0e3ed"}}>
                <p style=
                    {{ float: "left", color:"white", background: "#ffffff", height: "50px", fontSize: "20px",lineHeight: "50px", textAlign: "center", padding: "0 20px",
                        boxShadow: "inset 0 1px #5f789e, 0 1px 1px 0 rgba(0, 0, 0, 0.1)", backgroundColor: "#485b7a", backgroundImage: "-webkit-linear-gradient(#4d6283, #40516c"
                    }}
                >
                    <b>COMPAREQUOTE</b>
                </p>
                <div style={{ float: "left", lineHeight: "50px", fontSize: "16px", padding: "0 20px", color: "#5c617b"}}>
                    <h1>Quotes</h1>
                </div>
                
                <div style={{float: "right", padding: "0 20px", lineHeight: "50px"}}>
                    Welcome <a href="/">Johan</a> |
                    <a href="/">User Admin</a> |
                    <a href="/" onClick={logout}>Logout</a> |
                    <a href="/">Contact</a>
                </div>
            </div>
            <div style={{ height: "30px", backgroundColor: "#f7f7f9", borderBottom: "1px solid #bfcfda", boxShadow: "inset 0 1px #fff" }}>
                <ul className="w-full flex justify-items-center">
                    <li className="w-40 text-center group relative dropdown px-4 hover:text-white hover:bg-blue-comparemed-light cursor-pointer">
                        <a href="/quotes/create" className="text-blue-comparemed-dark hover:text-white" style={{ fontSize: "14px", lineHeight: "30px", fontWeight: "700" }}>New Short Quote</a>
                        <div className="group-hover:block dropdown-menu absolute left-0 hidden h-auto">
                            <ul className="top-0 w-40 bg-white shadow px:0">
                                <li className="text-black text-center" style={{ lineHeight: "30px"}}><a href="/quotes/" className="block hover:text-white hover:bg-blue-comparemed-light cursor-pointer">History</a></li>
                            </ul>
                        </div>
                    </li>
                    <li className="w-40 text-center group relative dropdown px-4 hover:text-white hover:bg-blue-comparemed-light cursor-pointer">
                        <a href="/gap-quotes/create" className="text-blue-comparemed-dark hover:text-white" style={{ fontSize: "14px", lineHeight: "30px", fontWeight: "700" }}>New Gap Quote</a>
                        <div className="group-hover:block dropdown-menu absolute left-0 hidden h-auto">
                            <ul className="top-0 w-40 bg-white shadow px:0">
                                <li className="text-black text-center" style={{ lineHeight: "30px"}}><a href="/gap-quotes/quick" className="block hover:text-white hover:bg-blue-comparemed-light cursor-pointer">Quick Quote</a></li>
                                <li className="text-black text-center" style={{ lineHeight: "30px"}}><a href="/gap-quotes/" className="block hover:text-white hover:bg-blue-comparemed-light cursor-pointer">History</a></li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
}
