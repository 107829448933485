import React from "react";
import { useField } from "formik";
import TextareaAutosize from 'react-textarea-autosize';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons";

export default function CustomTextArea({ label, labelTop, size, ...props }) {
    const [field, meta] = useField(props);
    // change the input class depending if we have a prefix / error
    var [inputBorder, inputSize] = "";
    // set field value as empty string if null
    if (field.value === null) field.value = "";
    (meta.touched && meta.error) ? inputBorder += "focus:ring-red-500 focus:border-red-500 border-red-500 text-red-500": inputBorder += "focus:ring-blue-500 focus:border-blue-500 border-gray-400";
    (size) ? inputSize = size : inputSize = "w-full";


    return (
        <div className="py-2">
            <div className={`flex justify-between ${inputSize}`}>
                { label && (
                    <label htmlFor={field.name} className="block text-sm font-medium text-gray-700">
                        {label}
                    </label>
                )}
            </div>
            <div className="mt-1 relative rounded-md shadow-sm">       
                <TextareaAutosize
                    {...field} {...props}
                    id={field.name}
                    className={`py-2 px-3 shadow-sm ${inputSize} block sm:text-sm border rounded-md ${inputBorder}`}
                />
                {meta.touched && meta.error && (
                    <div className="absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none">
                        <FontAwesomeIcon className="h-5 w-5 text-red-500" aria-hidden="true" icon={faExclamationCircle}/>
                    </div>
                )}
            </div>
            {meta.touched && meta.error && (
                <p className="mt-2 w-52 text-sm text-red-500" id="email-error">
                    {meta.error}
                </p>
            )}
        </div>
    );
}
