import { faFileImport, faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import * as XLSX from 'xlsx'

export default function ExcelImport({form, field}) {
    const [loading, setLoading] = useState(false);

    const readExcel = (file) => {
        const promise = new Promise((resolve, reject) => {
            if (!loading) {
                try {
                    setLoading(true);
                    const fileReader = new FileReader();
                    fileReader.readAsArrayBuffer(file);
            
                    if (file) {
                        fileReader.onload = (e) => {
                            const bufferArray = e.target.result;
                    
                            const wb = XLSX.read(bufferArray, { type: "buffer" });
                    
                            const wsname = wb.SheetNames[0];
                    
                            const ws = wb.Sheets[wsname];
                    
                            const data = XLSX.utils.sheet_to_json(ws);

                            // hardcoding some checks as this is a nightmare dynamically
                            if (data) {
                                data.forEach(row =>
                                    Object.keys(row).forEach(value => {
                                        switch(row[value]) {
                                            case "No":
                                                row[value] = 0;
                                                return;
                                            case "Yes":
                                            case "Insured":
                                                row[value] = 1;
                                                return;                                                
                                            case "Bank":
                                                row[value] = 2;
                                                return;
                                            case "Third Party":
                                                row[value] = 3;
                                                return;
                                            default: 
                                                return;
                                        }
                                    })
                                )
                            }
                            console.log(data);
                            resolve(data);
                        };
                    }

                } catch (error) {
                    setLoading(false);
                } 
            }
        });
    
        promise.then((d) => {
            form.setFieldValue(field.name, d);
            setLoading(false);
        });
    };

    return (
        <label className="button blue" title="All data has to follow the exact layout of the example provided">
            {loading ? (
                <FontAwesomeIcon icon={faSpinnerThird} className="animate-spin m-1 text-xs" />
            ) : (                
                <span><FontAwesomeIcon icon={faFileImport}/>&nbsp;Import a file</span>
            )}
            
            <input 
                type='file' 
                className="hidden" 
                onClick={(event)=> { 
                    event.target.value = null
                }}
                onChange={(e) => {
                    const file = e.target.files[0];
                    readExcel(file);
                }}
            />
        </label>
    );
}
