import React, { useState } from "react";
import { openModal } from "../../app/common/modals/modalReducer";
import { useDispatch } from "react-redux";
import axios from "axios";
import CustomText from "../../app/common/form/CustomText";
import { Formik, Form, Field} from "formik";
import * as Yup from 'yup';
import CustomButton from "../../app/common/form/CustomButton";
import CustomSelect from "../../app/common/form/CustomSelect";
import { lookups } from "../../app/common/lookup/lookups";
import CustomCheckbox from "../../app/common/form/CustomCheckbox";

export default function GapQuotesCreate(props) {
    const dispatch = useDispatch();
    const quoteID = props.match.params.id;
    const [initialValues] = useState({
        company_name: "",
        contact_title: "",
        contact_initials: "",
        contact_first_name: "",
        contact_surname: "",
        contact_work_tel: "",
        contact_cellphone: "",
        contact_email: "",
        contact_position: "",
        contact_address: "",
        contact_preferred_communication: "",
        contact_id_number: "",
    });

    return (
        <div className="block-container">
            <Formik
                initialValues={initialValues}
                enableReinitialize="true"
                validationSchema={Yup.object({
                    company_name: Yup.string()
                                        .required('The company name is required'),
                    contact_title: Yup.string()
                                        .max(5, 'The title cannot be more than 5 characters'),
                    contact_initials: Yup.string()
                                        .max(5, 'The initials cannot be more than 5 characters'),
                    contact_cellphone: Yup.string()
                                        .required('The cell is required')
                                        .matches(/^[0-9]*$/, 'Cellphone cannot have spaces or symbols'),
                    contact_email: Yup.string()
                                        .required('The email is required')
                                        .email('The email must be a valid email'),
                })}
                onSubmit={async (values, { setSubmitting, setErrors }) => {
                    console.log(values);
                    let formData = {
                        company_name: values.company_name,
                        contact_title: values.contact_title,
                        contact_initials: values.contact_initials,
                        contact_first_name: values.contact_first_name,
                        contact_surname: values.contact_surname,
                        contact_work_tel: values.contact_work_tel,
                        contact_cellphone: values.contact_cellphone,
                        contact_email: values.contact_email,
                        contact_position: values.contact_position,
                        contact_address: values.contact_address,
                        contact_preferred_communication: values.contact_preferred_communication,
                        contact_id_number: values.contact_id_number,
                        email_client: values.email_client,
                    }
                    
                    try {
                        await axios.post(process.env.REACT_APP_API_URL + "quotes", formData)
                        .then((response) => {
                            console.log(response.data);
                            dispatch(openModal({modalType: "ModalNotification", modalProps: {
                                type: 'success',
                                heading: 'Success',
                                body: 'The quote has been created successfully',
                                closeText: 'Close',
                                confirmText: 'Edit Quote',
                                confirmLink: '/quotes/edit/' + response.data.id
                            }}))
                        });
                    } catch (error) {
                        dispatch(openModal({modalType: "ModalNotification", modalProps: {
                            type: 'error',
                            heading: 'Something went wrong',
                            body: 'Please try again',
                            closeText: 'Close',
                        }}))
                    }
                }}
            >
                {({isSubmitting, errors}) => (
                    <Form>
                        <h2 className="py-10">Proposer/Client Details</h2>
                        <div className="flex flex-wrap">
                            <div className="input-container">
                                <CustomText label="Company Name" name="company_name" type="text" required/>
                            </div>
                        </div>
                        <h2 className="py-10">Contact Details</h2>
                        <div className="flex flex-wrap">
                            <div className="input-container">
                                <CustomText label="Title" name="contact_title" type="text" />
                                <CustomText label="Email" name="contact_email" type="email" required />
                                <CustomText label="Position (designation)" name="contact_position" type="text" />
                            </div>
                            <div className="input-container">
                                <CustomText label="Initials" name="contact_initials" type="text" />
                                <CustomText label="ID/passport number" name="contact_id_number" type="text" />
                                <CustomText label="Address/branch" name="contact_address" type="text" />
                            </div>
                            <div className="input-container">
                                <CustomText label="First name(s)" name="contact_first_name" type="text" />
                                <CustomText label="Work tel" name="contact_work_tel" type="text" />
                                <Field
                                    label="Preferred Communication"
                                    options={lookups.preferredCommunication}
                                    name='contact_preferred_communication'
                                    component={CustomSelect}
                                />
                            </div>
                            <div className="input-container">
                                <CustomText label="Surname" name="contact_surname" type="text" />
                                <CustomText label="Cellphone" name="contact_cellphone" type="text" required/>
                            </div>
                        </div>
                        <CustomCheckbox
                            label="Email Form to Client"
                            name="email_client"
                            value="true"
                        />
                        {Object.keys(errors).length !== 0 && (
                            <h1 className="my-2 text-red-500">Cannot Submit. Please check all fields before sumitting</h1>
                        )}                       
                        <br className="py-5" />
                        <CustomButton
                            disabled={isSubmitting}
                            submitBool={isSubmitting}
                            type="submit"
                            text={quoteID > 0 ? "Update Quote" : "Create Quote"}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
}
