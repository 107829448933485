import React, { Fragment, useState } from 'react'
import { useHistory } from "react-router-dom";
import { closeModal } from "../../app/common/modals/modalReducer";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle, faExclamationCircle, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { Dialog, Transition } from '@headlessui/react';

export default function ModalNotification(props) {
    let history = useHistory();
    const dispatch = useDispatch();
    const [open] = useState(true);
  
    let modalIcon = faCheckCircle;
    let modalIconClass = "my-4 text-2xl ";

    switch(props.type) {
        case 'error':
            modalIcon = faTimesCircle;
            modalIconClass += "text-red-500";
            break;
        
        case 'warning':
            modalIcon = faExclamationCircle;
            modalIconClass += "text-yellow-500";
            break;

        default:
            modalIconClass += "text-green-500";
            break;
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={open} onClose={() => dispatch(closeModal())}>
                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                <button
                                    type="button"
                                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                                    onClick={() => dispatch(closeModal())}
                                >
                                    <span className="sr-only">Close</span>
                                    <FontAwesomeIcon className="h-6 w-6" aria-hidden="true" icon={faTimes}/>
                                </button>
                            </div>
                            <div className="sm:flex sm:items-start">
                                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <FontAwesomeIcon className={modalIconClass} aria-hidden="true" icon={modalIcon}/>
                                </div>
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title as="h1" className="text-lg leading-6 font-medium text-gray-900">
                                        {props.heading}
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p>
                                            {props.body}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                {props.confirmText && props.confirmLink && (
                                    <button
                                        className="button blue"
                                        onClick={() => {                                    
                                            dispatch(closeModal());
                                            history.push(props.confirmLink);
                                        }}
                                    >
                                        {props.confirmText}
                                    </button>
                                    
                                )}
                                <button
                                    type="button"
                                    className="button"
                                    onClick={() => dispatch(closeModal())}
                                >
                                    {props.closeText}
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}