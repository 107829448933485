import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";

export default function CustomButton({ submitBool, icon, text, ...props }) {
    return (
        <button
            {...props}
            disabled={submitBool}
        >
            {submitBool ? (
                <>
                    <FontAwesomeIcon icon={faSpinnerThird} className="animate-spin text-xl" />
                </>
            ) : (
                <>
                    <FontAwesomeIcon
                        className='flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                        aria-hidden="true"
                        icon={icon}
                    />
                    <span className="truncate">{text}</span>
                </>
            )}
        </button>
    );
}
