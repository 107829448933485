import React, { useEffect, useState } from "react";
import { openModal } from "../../app/common/modals/modalReducer";
import { authorizeProposalForm } from "../auth/authActions";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import CustomText from "../../app/common/form/CustomText";
import { Formik, Form, Field, FieldArray} from "formik";
import * as Yup from 'yup';
import LoadingComponent from "../layout/LoadingComponent";
import CustomButton from "../../app/common/form/CustomButton";
import CustomSelect from "../../app/common/form/CustomSelect";
import CustomTextArea from "../../app/common/form/CustomTextArea"
import ExcelImport from "../../app/common/import/ExcelImport"
// import {lookups} from "../../app/common/lookup/lookups";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload, faPlusCircle, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import SignatureCanvas from 'react-signature-canvas'

export default function ShortQuotesProposalForm(props) {
    const dispatch = useDispatch();
    const quoteID = props.match.params.id;
    const { authenticated, proposalForm } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(true);
    const [insurers, setInsurers] = useState([]);
    const [lookups, setLookups] = useState([]);

    const date = new Date();
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const fullDate = date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();

    var sigPad = {};
    const clear = () => { sigPad.clear() }

    useEffect(() => {
        (
            async () => {
                // commented out to remove the verification stage for testing
                // dispatch(openModal({modalType: "ModalPassword", modalProps: {
                //     type: 'quoteProposalForm',
                //     heading: 'This proposal is encrypted',
                //     body: 'Please enter the password provided by your consultant',
                //     access_key: props.match.params.access_key,
                //     id: props.match.params.id,
                //     closeText: 'Close',
                // }}))
                if (loading) {
                    // get the proposal form
                    const {data} = await axios.get(process.env.REACT_APP_API_URL + "quotes/" + props.match.params.id, {});
                    // Deon testing
                    // const data = {};
                    // const insurers = [
                    //     {"value": 1, "label": "Sanlam"},
                    //     {"value": 2, "label": "ONE"},
                    //     {"value": 3, "label": "Hollard"},
                    //     {"value": 4, "label": "Merx"},
                    //     {"value": 5, "label": "Senate"},
                    //     {"value": 6, "label": "Transit"},
                    //     {"value": 7, "label": "XLTransit"}
                    // ];
                    dispatch(authorizeProposalForm(data));

                    const insurers = await axios.get(process.env.REACT_APP_API_URL + "schemes", {});
                    setInsurers(insurers.data);
                    // set lookup data
                    const lookupsResponse = await axios.get(process.env.REACT_APP_API_URL + "lookups", {});
                    setLookups(lookupsResponse.data);
                    // setInsurers(insurers);

                    // remove loading
                    setLoading(false);
                }
            }
        )();
    });

    if (loading || !authenticated) {
        return <LoadingComponent message="Loading"/>
    }

    return (
        <>
            <h1 className="text-2xl ml-10 my-5">Comparequote - Heavy Commercial Vehicle and Goods in Transit Insurance Proposal</h1>
            <div className="block-container">
                <Formik
                    initialValues={proposalForm}
                    enableReinitialize="true"
                    validationSchema={Yup.object({
                        company_depos: Yup.number()
                                            .max(50, 'The number of depos cannot be more than 50'),
                        company_name: Yup.string()
                                            .required('The company name is required'),
                        contact_title: Yup.string()
                                            .max(5, 'The title cannot be more than 5 characters'),
                        contact_initials: Yup.string()
                                            .max(5, 'The initials cannot be more than 5 characters'),
                        contact_cellphone: Yup.string()
                                            .required('The cellphone is required')
                                            .matches(/[\d]$/, 'Cellphone cannot have spaces or symbols'),
                        contact_email: Yup.string()
                                            .required('The email is required')
                                            .email('The email must be a valid email'),
                    })}
                    onSubmit={async (values, { setSubmitting, setErrors }) => {
                        // information for the quote
                        const formData = {
                            company_name:                       values.company_name,
                            company_vat_number:                 values.company_vat_number,
                            company_physical_address:           values.company_physical_address,
                            company_trading_name:               values.company_trading_name,
                            company_telephone:                  values.company_telephone,
                            company_postal_address:             values.company_postal_address,
                            company_registration_number:        values.company_registration_number,
                            company_email:                      values.company_email,
                            company_description:                values.company_description,
                            company_type:                       values.company_type,
                            company_depos:                      values.company_depos,
                            company_other_trading_name:         values.company_other_trading_name,
                            company_other_trading_name_details: values.company_other_trading_name_details,

                            contact_preferred_language:         values.contact_preferred_language,
                            contact_title:                      values.contact_title,
                            contact_email:                      values.contact_email,
                            contact_position:                   values.contact_position,
                            contact_initials:                   values.contact_initials,
                            contact_id_number:                  values.contact_id_number,
                            contact_address:                    values.contact_address,
                            contact_first_name:                 values.contact_first_name,
                            contact_work_tel:                   values.contact_work_tel,
                            contact_preferred_communication:    values.contact_preferred_communication,
                            contact_surname:                    values.contact_surname,
                            contact_cellphone:                  values.contact_cellphone,

                            general_insurer_declined:           values.general_insurer_declined,
                            general_insurer_declined_policy:    values.general_insurer_declined_policy,
                            general_insurer_declined_reasons:   values.general_insurer_declined_reasons,
                            general_area_operation:             values.general_area_operation,
                            general_insurer_cancelled:          values.general_insurer_cancelled,
                            general_insurer_cancelled_policy:   values.general_insurer_cancelled_policy,
                            general_insurer_cancelled_reasons:  values.general_insurer_cancelled_reasons,
                            general_operating_hours:            values.general_operating_hours,
                            general_reason_requesting:          values.general_reason_requesting,
                            general_foreign_drivers:            values.general_foreign_drivers,
                            general_underwriting:               values.general_underwriting,
                            general_cross_border_areas:         values.general_cross_border_areas,
                            general_telematics:                 values.general_telematics,
                            general_telematics_details:         values.general_telematics_details,

                            vehicles_security:                  values.vehicles_security,
                            vehicles_parked:                    values.vehicles_parked,
                            vehicles_parked_details:            values.vehicles_parked_details,
                            vehicles_driven_by:                 values.vehicles_driven_by,
                            vehicles_registered_name:           values.vehicles_registered_name,
                            vehicles_registered_name_details:   values.vehicles_registered_name_details,

                            motor_insurance_requested:          values.motor_insurance_requested,
                            motor_extensions_requested:         values.motor_extensions_requested,

                            git_insurance:                      values.git_insurance,
                            git_goods_carried:                  values.git_goods_carried,
                            git_sub_contractor:                 values.git_sub_contractor,
                            git_sub_contractor_details:         values.git_sub_contractor_details,
                            git_load:                           values.git_load,
                            git_cover:                          values.git_cover,
                            git_cover_haulage_fee:              values.git_cover_haulage_fee,
                            git_extensions:                     values.git_extensions,

                            territorial_limits:                 values.territorial_limits,

                            signature:                          sigPad.toDataURL("image/png"),

                            // Arrays
                            drivers:                            values.drivers,
                            claims:                             values.claims,
                            vehicles:                           values.vehicles,
                            sub_contractors:                    values.sub_contractors,
                        }

                        try {
                            await axios.put(process.env.REACT_APP_API_URL + "quotes/" + quoteID, formData)
                            .then(() => {
                                dispatch(openModal({modalType: "ModalNotification", modalProps: {
                                    type: 'success',
                                    heading: 'Success',
                                    body: 'The data has been updated successfully',
                                    closeText: 'Close',
                                }}))
                            });
                        } catch (error) {
                            dispatch(openModal({modalType: "ModalNotification", modalProps: {
                                type: 'error',
                                heading: 'Something went wrong',
                                body: 'Please try again',
                                closeText: 'Close',
                            }}))
                        }
                    }}
                >
                    {({isSubmitting, errors, values}) => (
                        <Form>
                            <div className="flex flex-wrap">
                                <div className="input-container">
                                    <Field
                                        label="Preferred language"
                                        options={lookups.preferredLanguage}
                                        name='contact_preferred_language'
                                        component={CustomSelect}
                                    />
                                </div>
                                <div className="input-container">
                                    <Field
                                        label="Preferred insurer"
                                        options={insurers}
                                        name='schemes'
                                        component={CustomSelect}
                                        multi
                                    />
                                </div>
                            </div>
                            {/* Company Details */}
                            <h2 className="py-10">Proposer/Client Details</h2>
                            <div className="flex flex-wrap">
                                <div className="input-container">
                                    <CustomText label="Company name" name="company_name" type="text" required/>
                                    <CustomText label="VAT number" name="company_vat_number" type="text"/>
                                    <CustomTextArea label="Physical address" name="company_physical_address" />
                                </div>
                                <div className="input-container">
                                    <CustomText label="Trading name (if applicable)" name="company_trading_name" type="text"/>
                                    <CustomText label="Telephone" name="company_telephone" type="text"  />
                                    <CustomTextArea label="Postal address" name="company_postal_address"/>
                                </div>
                                <div className="input-container">
                                    <CustomText label="Company registration number/CK Number" name="company_registration_number" type="text"/>
                                    <CustomText label="Email" name="company_email" type="email" />
                                    <CustomTextArea label="Description of business" name="company_description" />
                                </div>
                                <div className="input-container">
                                    <Field
                                        label="Type"
                                        options={lookups.companyType}
                                        name='company_type'
                                        component={CustomSelect}
                                    />
                                    <CustomText label="Number of depos" name="company_depos" type="number" />
                                    <Field
                                        label="Has anyone ever traded under a different name?"
                                        options={lookups.yesnoReplaceYes}
                                        name='company_other_trading_name'
                                        component={CustomSelect}
                                    />
                                    {values.company_other_trading_name === 1 && (
                                        <CustomText label="Name" name="company_other_trading_name_details" type="text" />
                                    )}
                                </div>
                            </div>
                            {/* Contact Details */}
                            <h2 className="py-10">Contact Details</h2>
                            <div className="flex flex-wrap">
                                <div className="input-container">
                                    <Field
                                        label="Title"
                                        options={lookups.titles}
                                        name='contact_title'
                                        component={CustomSelect}
                                    />
                                    <CustomText label="Email" name="contact_email" type="email" required />
                                    <CustomText label="Position (designation)" name="contact_position" type="text" />
                                </div>
                                <div className="input-container">
                                    <CustomText label="Initials" name="contact_initials" type="text" />
                                    <CustomText label="ID/passport number" name="contact_id_number" type="text" />
                                    <CustomText label="Address/branch" name="contact_address" type="text" />
                                </div>
                                <div className="input-container">
                                    <CustomText label="First name(s)" name="contact_first_name" type="text" />
                                    <CustomText label="Work tel" name="contact_work_tel" type="text" />
                                    <Field
                                        label="Preferred Communication"
                                        options={lookups.preferredCommunication}
                                        name='contact_preferred_communication'
                                        component={CustomSelect}
                                    />
                                </div>
                                <div className="input-container">
                                    <CustomText label="Surname" name="contact_surname" type="text" />
                                    <CustomText label="Cellphone" name="contact_cellphone" type="text" required/>
                                </div>
                            </div>
                            {/* General Material Disclosures */}
                            <h2 className="py-10">General Material Disclosures</h2>
                            <div className="flex flex-wrap">
                                <div className="input-container">
                                    <Field
                                        label="Has any insurer ever declined your proposal"
                                        options={lookups.yesno}
                                        name='general_insurer_declined'
                                        component={CustomSelect}
                                    />
                                    {values.general_insurer_declined === 1 && values.general_insurer_declined !== null && (
                                        <>
                                            <CustomText label="Policy Number(s)" name="general_insurer_declined_policy" type="text" />
                                            <CustomTextArea label="Reason(s)" name="general_insurer_declined_reasons" />
                                        </>
                                    )}
                                    <Field
                                        label="Main areas of operation/routes"
                                        options={lookups.provinces}
                                        name='general_area_operation'
                                        component={CustomSelect}
                                        multi
                                    />
                                </div>
                                <div className="input-container">
                                    <Field
                                        label="Has a insurer ever cancelled/refused to renew a policy"
                                        options={lookups.yesno}
                                        name='general_insurer_cancelled'
                                        component={CustomSelect}
                                    />
                                    {values.general_insurer_cancelled === 1 && values.general_insurer_cancelled !== null && (
                                        <>
                                            <CustomText label="Policy Number(s)" name="general_insurer_cancelled_policy" type="text" />
                                            <CustomTextArea label="Reason(s)" name="general_insurer_cancelled_reasons"  />
                                        </>
                                    )}
                                    <Field
                                        label="Daily operating hours?"
                                        options={lookups.operatingHours}
                                        name='general_operating_hours'
                                        component={CustomSelect}
                                    />
                                </div>
                                <div className="input-container">
                                    <Field
                                        label="Reasons for requesting a quote"
                                        options={lookups.reasonRequestingQuote}
                                        name='general_reason_requesting'
                                        component={CustomSelect}
                                    />
                                    <Field
                                        label="Foreign national drivers"
                                        options={lookups.yesno}
                                        name='general_foreign_drivers'
                                        component={CustomSelect}
                                    />
                                </div>
                                <div className="input-container">
                                    <Field
                                        label="Underwriting disclosures"
                                        options={lookups.underwriting}
                                        name='general_underwriting'
                                        component={CustomSelect}
                                        multi
                                    />
                                    {values.general_underwriting !== null && values.general_underwriting !== undefined && values.general_underwriting.includes('3') && (
                                        <Field
                                            label="Cross border areas"
                                            options={lookups.countries}
                                            name='general_cross_border_areas'
                                            component={CustomSelect}
                                            multi
                                        />
                                    )}
                                    <Field
                                        label="Telematics installed"
                                        options={lookups.yesnoReplaceYes}
                                        name='general_telematics'
                                        component={CustomSelect}
                                    />
                                    {values.general_telematics === 1 && (
                                        <Field
                                            label="Detail any measures in place that can prevent any claims"
                                            options={lookups.telematics}
                                            name='general_telematics_details'
                                            component={CustomSelect}
                                            multi
                                        />
                                    )}
                                </div>
                            </div>
                            {/* Vehicle Details */}
                            <h2 className="py-10">Vehicle Details</h2>
                            <div className="flex flex-wrap">
                                <div className="input-container">
                                    <Field
                                        label="What security measures are used"
                                        options={lookups.sercurityMeasures}
                                        name='vehicles_security'
                                        component={CustomSelect}
                                        multi
                                    />
                                </div>
                                <div className="input-container">
                                    <Field
                                        label="Where are the vehicles parked on route"
                                        options={lookups.vehiclesParked}
                                        name='vehicles_parked'
                                        component={CustomSelect}
                                    />
                                    {values.vehicles_parked !== 1 && (
                                        <CustomText label="Supply Details" name="vehicles_parked_details" type="text" />
                                    )}
                                </div>
                                <div className="input-container">
                                    <Field
                                        label="Who will the truck be driven by?"
                                        options={lookups.truckDrivers}
                                        name='vehicles_driven_by'
                                        component={CustomSelect}
                                        multi
                                    />
                                </div>
                            </div>
                            <FieldArray name="drivers">
                                {({ push, remove }) => (
                                    <>
                                        <div className="flex flex-col pb-10">
                                            {values.drivers !== undefined && values.drivers.length > 0 && (
                                                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                        <div className="overflow-hidden border-b shadow border-gray-200">
                                                            <table className="min-w-full divide-y divide-gray-200">
                                                                <thead>
                                                                    <tr>
                                                                        <th
                                                                            scope="col"
                                                                            className="pl-2 py-3 text-left"
                                                                        >
                                                                            Title
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className=" py-3 text-left font-text-black"
                                                                        >
                                                                            First name(s)
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className=" py-3 text-left font-text-black"
                                                                        >
                                                                            Surname
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className=" py-3 text-left font-text-black"
                                                                        >
                                                                            Email
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className=" py-3 text-left font-text-black"
                                                                        >
                                                                            ID number
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className=" py-3 text-left font-text-black"
                                                                        >
                                                                            Cellphone
                                                                        </th>
                                                                        <th scope="col" className="relative px-6 py-3">
                                                                            <span className="sr-only">Remove</span>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {values.drivers.map((d, index) => (
                                                                        <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                                                            <td className="pl-2 py-4">
                                                                                <CustomText name={`drivers[${index}].driver_title`} value={d.driver_title} type="text" size="w-14"/>
                                                                            </td>
                                                                            <td className="py-4">
                                                                                <CustomText name={`drivers[${index}].driver_first_name`} value={d.driver_first_name} type="text" size="lg:w-28 xl:w-40"/>
                                                                            </td>
                                                                            <td className="py-4">
                                                                                <CustomText name={`drivers[${index}].driver_surname`} value={d.driver_surname} type="text" size="lg:w-28 xl:w-40"/>
                                                                            </td>
                                                                            <td className="py-4">
                                                                                <CustomText name={`drivers[${index}].driver_email`} value={d.driver_email} type="text" size="lg:w-28 xl:w-40"/>
                                                                            </td>
                                                                            <td className="py-4">
                                                                                <CustomText name={`drivers[${index}].driver_id_number`} value={d.driver_id_number} type="text" size="lg:w-28 xl:w-32"/>
                                                                            </td>
                                                                            <td className="py-4">
                                                                                <CustomText name={`drivers[${index}].driver_cellphone`} value={d.driver_cellphone} type="text" size="lg:w-28 xl:w-40"/>
                                                                            </td>
                                                                            <td className="text-center font-medium">
                                                                                <FontAwesomeIcon
                                                                                    className="text-red-500 mx-auto mt-2 hover:text-red-700 cursor-pointer"
                                                                                    onClick={() => remove(index)}
                                                                                    icon={faTrashAlt}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <button
                                            type="button"
                                            className="button blue"
                                            onClick={() => push({
                                                driver_title: "", driver_first_name: "", driver_surname: "",
                                                driver_cellphone: "", driver_email: "", driver_id_number: ""
                                            })}
                                        >
                                            <span><FontAwesomeIcon icon={faPlusCircle}/>&nbsp;Add a Driver</span>
                                        </button>
                                        <Field name="drivers" component={ExcelImport}/>
                                        <a href="/assets/documents/Driver Example.xlsx" download className="button blue" ><span><FontAwesomeIcon icon={faFileDownload}/>&nbsp;Download Example</span></a>
                                    </>
                                )}
                            </FieldArray>
                            {/* Claims History */}
                            <h2 className="py-10">Claims History</h2>
                            <p className="pb-2 text-gray-800 text-sm">Please advise details of all losses during last 3 years , for the entities under the following headings</p>
                            <FieldArray name="claims">
                                {({ push, remove }) => (
                                    <>
                                        <div className="flex flex-col pb-10">
                                            {values.claims !== undefined && values.claims.length > 0 && (
                                                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                        <div className="overflow-hidden border-b shadow border-gray-200">
                                                            <table className="min-w-full divide-y divide-gray-200">
                                                                <thead>
                                                                    <tr>
                                                                        <th
                                                                            scope="col"
                                                                            className="pl-2 py-3 text-left"
                                                                        >
                                                                            Date of loss
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className=" py-3 text-left font-text-black"
                                                                        >
                                                                            Vehicle type
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className=" py-3 text-left font-text-black"
                                                                        >
                                                                            Vehicle sum insured
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className=" py-3 text-left font-text-black"
                                                                        >
                                                                            Claims description
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className=" py-3 text-left font-text-black"
                                                                        >
                                                                            Gross claims
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className=" py-3 text-left font-text-black"
                                                                        >
                                                                            Nett claims
                                                                        </th>
                                                                        <th scope="col" className="relative px-6 py-3">
                                                                            <span className="sr-only">Remove</span>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {values.claims.map((d, index) => (
                                                                        <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                                                            <td className="pl-2 py-4">
                                                                                <CustomText name={`claims[${index}].claim_date_of_loss`} value={d.claim_date_of_loss} type="date" size="lg:w-28 xl:w-40"/>
                                                                            </td>
                                                                            <td className="py-4">
                                                                                <CustomText name={`claims[${index}].claim_vehicle_type`} value={d.claim_vehicle_type} type="text" size="lg:w-28 xl:w-40"/>
                                                                            </td>
                                                                            <td className="py-4">
                                                                                <CustomText name={`claims[${index}].claim_vehicle_sum_insured`} value={d.claim_vehicle_sum_insured} type="number" prefix="R" size="lg:w-28 xl:w-40"/>
                                                                            </td>
                                                                            <td className="py-4">
                                                                                <CustomTextArea name={`claims[${index}].claim_description`} value={d.claim_description} size="lg:w-28 xl:w-40"/>
                                                                            </td>
                                                                            <td className="py-4">
                                                                                <CustomText name={`claims[${index}].claim_gross`} value={d.claim_gross} type="number" prefix="R" size="lg:w-28 xl:w-40"/>
                                                                            </td>
                                                                            <td className="py-4">
                                                                                <CustomText name={`claims[${index}].claim_nett`} value={d.claim_nett} type="number" prefix="R" size="lg:w-28 xl:w-40"/>
                                                                            </td>
                                                                            <td className="text-center font-medium">
                                                                                <FontAwesomeIcon className="text-red-500 mx-auto mt-2 hover:text-red-700 cursor-pointer" onClick={() => remove(index)} icon={faTrashAlt}/>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <button
                                            type="button"
                                            className="button blue"
                                            onClick={() => push({
                                                claim_date_of_loss: "", claim_vehicle_type: "", claim_vehicle_sum_insured: "",
                                                claim_description: "", claim_gross: "", claim_nett: ""
                                            })}
                                        >
                                            <span><FontAwesomeIcon icon={faPlusCircle}/>&nbsp;Add a Claim</span>                                            
                                        </button>
                                        <Field name="claims" component={ExcelImport}/>
                                        <a href="/assets/documents/Claim Example.xlsx" download className="button blue" ><span><FontAwesomeIcon icon={faFileDownload}/>&nbsp;Download Example</span></a>
                                    </>
                                )}
                            </FieldArray>
                            {/* Moto Dropdowns */}
                            <div className="py-5 flex flex-wrap">
                                <div className="input-container">
                                    <Field
                                        label="Motor insurance requested"
                                        options={lookups.motorInsurance}
                                        name='motor_insurance_requested'
                                        component={CustomSelect}
                                    />
                                </div>
                                <div className="input-container">
                                    <Field
                                        label="Motor extensions required"
                                        options={lookups.motorExtensions}
                                        name='motor_extensions_requested'
                                        component={CustomSelect}
                                        multi
                                    />
                                </div>
                            </div>
                            {/* Vehicle details */}
                            <br className="py-10" />
                            <FieldArray name="vehicles" >
                                {({ push, remove }) => (
                                    <>
                                        <div className="flex flex-col pb-10">
                                            {values.vehicles !== undefined && values.vehicles.length > 0 && (
                                                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                        <div className="pb-24 overflow-hidden border-b shadow border-gray-200">
                                                            <table className="min-w-full divide-y divide-gray-200">
                                                                <thead>
                                                                    <tr>
                                                                        <th
                                                                            scope="col"
                                                                            className="pl-2 py-3 text-left"
                                                                        >
                                                                            Make
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className=" py-3 text-left font-text-black"
                                                                        >
                                                                            Model
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className=" py-3 text-left font-text-black"
                                                                        >
                                                                            Year
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className=" py-3 text-left font-text-black"
                                                                        >
                                                                            Vin
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className=" py-3 text-left font-text-black"
                                                                        >
                                                                            Registration
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className=" py-3 text-left font-text-black"
                                                                        >
                                                                            Cover required
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className=" py-3 text-left font-text-black"
                                                                        >
                                                                            Value
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className=" py-3 text-left font-text-black"
                                                                        >
                                                                            GIT limit
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className=" py-3 text-left font-text-black"
                                                                        >
                                                                            GIT cover
                                                                        </th>
                                                                        <th
                                                                            scope="col"
                                                                            className=" py-3 text-left font-text-black"
                                                                        >
                                                                            Registered owner/holder
                                                                        </th>
                                                                        <th scope="col" className="relative px-6 py-3">
                                                                            <span className="sr-only">Remove</span>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {values.vehicles !== null && values.vehicles.map((d, index) => (
                                                                        <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                                                            <td className="pl-2 py-4">
                                                                                <CustomText name={`vehicles[${index}].vehicle_make`} value={d.vehicle_make} type="text" size="lg:w-16 xl:w-24"/>
                                                                            </td>
                                                                            <td className="py-4">
                                                                                <CustomText name={`vehicles[${index}].vehicle_model`} value={d.vehicle_model} type="text" size="lg:w-16 xl:w-24"/>
                                                                            </td>
                                                                            <td className="py-4">
                                                                                <CustomText name={`vehicles[${index}].vehicle_year`} value={d.vehicle_year} type="text" size="lg:w-16 xl:w-24"/>
                                                                            </td>
                                                                            <td className="py-4">
                                                                                <CustomText name={`vehicles[${index}].vehicle_insurance_number`} value={d.vehicle_insurance_number} type="text" size="lg:w-16 xl:w-24"/>
                                                                            </td>
                                                                            <td className="py-4">
                                                                                <CustomText name={`vehicles[${index}].vehicle_registration`} value={d.vehicle_registration} type="text" size="lg:w-16 xl:w-24"/>
                                                                            </td>
                                                                            <td className="py-4">
                                                                                <Field
                                                                                    options={lookups.yesno}
                                                                                    name={`vehicles[${index}].vehicle_cover`}
                                                                                    component={CustomSelect}
                                                                                    size="w-40"
                                                                                />
                                                                            </td>
                                                                            <td className="py-4">
                                                                                <CustomText name={`vehicles[${index}].vehicle_value`} type="number" value={d.vehicle_value} prefix="R" size="lg:w-20 xl:w-32"/>
                                                                            </td>
                                                                            <td className="py-4">
                                                                                <CustomText name={`vehicles[${index}].vehicle_git_limit`} type="number" value={d.vehicle_git_limit} prefix="R" size="lg:w-20 xl:w-32"/>
                                                                            </td>
                                                                            <td className="py-4">
                                                                                <CustomText name={`vehicles[${index}].vehicle_git_cover`} type="number" value={d.vehicle_git_cover} prefix="R" size="lg:w-20 xl:w-32"/>
                                                                            </td>
                                                                            <td className="py-4">
                                                                                <Field
                                                                                    options={lookups.vehiclesOwner}
                                                                                    name={`vehicles[${index}].vehicle_owner`}
                                                                                    component={CustomSelect}
                                                                                    size="w-40"
                                                                                />
                                                                            </td>
                                                                            <td className="py-4">
                                                                                <FontAwesomeIcon className="text-red-500 mx-auto mt-2 hover:text-red-700 cursor-pointer" onClick={() => remove(index)} icon={faTrashAlt}/>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <button
                                            type="button"
                                            className="button blue"
                                            onClick={() => push({
                                                vehicle_make: "", vehicle_model: "", vehicle_year: "",
                                                vehicle_insurance_number: "", vehicle_registration: "", vehicle_cover: "",
                                                vehicle_value: "", vehicle_git_limit: "", vehicle_git_cover: ""
                                            })}
                                        >
                                            <span><FontAwesomeIcon icon={faPlusCircle}/>&nbsp;Add a Vehicle</span>
                                        </button>
                                        <Field name="vehicles" component={ExcelImport}/>
                                        <a href="/assets/documents/Vehicle Example.xlsx" download className="button blue" ><span><FontAwesomeIcon icon={faFileDownload}/>&nbsp;Download Example</span></a>
                                    </>
                                )}
                            </FieldArray>
                            <div className="flex flex-wrap">
                                <div className="input-container">
                                    <Field
                                        label="Any vehicles registered in any name other than the proposer                                    "
                                        options={lookups.yesnoReplaceYes}
                                        name='vehicles_registered_name'
                                        component={CustomSelect}
                                    />
                                    {values.vehicles_registered_name === 1 && (
                                        <>
                                            <CustomTextArea label="Please provide the name of the registered owner and the nature of the relationship to the proposer." name="vehicles_registered_name_details" />
                                        </>
                                    )}
                                </div>
                            </div>
                            {/* GIT */}
                            <h2 className="py-10">GIT Details</h2>
                            <div className="flex flex-wrap">
                                <div className="input-container">
                                    <Field
                                        label="GIT insurance"
                                        options={lookups.gitInsurance}
                                        name='git_insurance'
                                        required
                                        component={CustomSelect}
                                    />
                                    <Field
                                        label="Goods Carried"
                                        name="git_goods_carried"
                                        options={lookups.goodsCarried}
                                        component={CustomSelect}
                                        multi
                                    />
                                </div>
                                <div className="input-container">
                                    <Field
                                        label="Do you have loads carried by sub-contractors"
                                        options={lookups.yesnoReplaceYes}
                                        name='git_sub_contractor'
                                        component={CustomSelect}
                                    />
                                    {values.git_sub_contractor === 1 && (
                                        <CustomText label="Name(s)" name="git_sub_contractor_details" type="text" />
                                    )}
                                </div>
                                <div className="input-container">
                                    <CustomText label="Maximum load limit required" name="git_load" type="number" prefix="R"/>
                                    <Field
                                        label="Do you require GIT contingency cover"
                                        options={lookups.yesnoReplaceYes}
                                        name='git_cover'
                                        component={CustomSelect}
                                    />
                                    {values.git_cover === 1 && (
                                        <>
                                            <CustomText label="Maximum annual haulage fee" name="git_cover_haulage_fee" type="number" prefix="R"/>
                                        </>
                                    )}
                                </div>
                                <div className="input-container">
                                    <Field
                                        label="GIT Extensions"
                                        options={lookups.gitExtensions}
                                        name='git_extensions'
                                        component={CustomSelect}
                                        multi
                                    />
                                </div>
                            </div>
                            {values.git_cover === 1 && values.git_cover !== undefined && (
                                <>
                                    {/* Sub-Contractor Section */}
                                    <p className="pb-2 text-gray-800 text-sm">Please add any sub crontactors that you use</p>
                                    <p className="pb-2 italic font-bold text-sm">Warranty - Cover only applies when Sub-contractors own insurance fails to settle a claim. Confirm that you check on a monthly basis that GIT cover is in place for same risks and limits as your own GIT insurance.</p>
                                    <FieldArray name="sub_contractors">
                                        {({ push, remove }) => (
                                            <>
                                                <div className="flex flex-col pb-10">
                                                {values.sub_contractors !== undefined && values.sub_contractors.length > 0 && (
                                                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                                            <div className="overflow-hidden border-b shadow border-gray-200">
                                                                <table className="min-w-full divide-y divide-gray-200">
                                                                    <thead>
                                                                        <tr>
                                                                            <th
                                                                                scope="col"
                                                                                className="pl-2 py-3 text-left"
                                                                            >
                                                                                Sub-contractors name
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                className=" py-3 text-left font-text-black"
                                                                            >
                                                                                Vehicle registration number
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                className=" py-3 text-left font-text-black"
                                                                            >
                                                                                Revenue generated on average per month
                                                                            </th>
                                                                            <th
                                                                                scope="col"
                                                                                className=" py-3 text-left font-text-black"
                                                                            >
                                                                                Load Limit
                                                                            </th>
                                                                            <th scope="col" className="relative px-6 py-3">
                                                                                <span className="sr-only">Remove</span>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {values.sub_contractors.map((d, index) => (
                                                                        <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                                                            <td className="pl-2 py-4">
                                                                                <CustomText name={`sub_contractors[${index}].sub_contractors_name`} value={d.sub_contractors_name} type="text" size="lg:w-28 xl:w-40"/>
                                                                            </td>
                                                                            <td className="pl-2 py-4">
                                                                                <CustomText name={`sub_contractors[${index}].sub_contractors_registration`} value={d.sub_contractors_registration} type="text" size="lg:w-28 xl:w-40"/>
                                                                            </td>
                                                                            <td className="pl-2 py-4">
                                                                                <CustomText name={`sub_contractors[${index}].sub_contractors_revenue`} value={d.sub_contractors_revenue} type="number" prefix="R" size="lg:w-28 xl:w-40"/>
                                                                            </td>
                                                                            <td className="pl-2 py-4">
                                                                                <CustomText name={`sub_contractors[${index}].sub_contractors_load`} value={d.sub_contractors_load} type="text" size="lg:w-28 xl:w-40"/>
                                                                            </td>
                                                                            <td className="text-center font-medium">
                                                                                <FontAwesomeIcon className="text-red-500 mx-auto mt-2 hover:text-red-700 cursor-pointer" onClick={() => remove(index)} icon={faTrashAlt}/>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                </div>
                                                <button
                                                    type="button"
                                                    className="button blue"
                                                    onClick={() => push({
                                                        sub_contractors_name: "", sub_contractors_registration: "",
                                                        sub_contractors_load: "", sub_contractors_revenue: ""
                                                    })}
                                                >                                                    
                                                    <span><FontAwesomeIcon icon={faPlusCircle}/>&nbsp;Add a sub contractor</span>
                                                </button>
                                                <Field name="sub_contractors" component={ExcelImport}/>
                                                <a href="/assets/documents/Sub Contractor Example.xlsx" download className="button blue" ><span><FontAwesomeIcon icon={faFileDownload}/>&nbsp;Download Example</span></a>
                                            </>
                                        )}
                                    </FieldArray>
                                </>
                            )}
                            {/* territorial limits */}
                            <div className="py-10">
                                <Field
                                    label="In which geographical areas is cover required"
                                    name="territorial_limits"
                                    options={lookups.countries}
                                    component={CustomSelect}
                                    multi
                                />
                            </div>

                            <div className="pb-2 italic font-bold text-sm">
                                Cover is always conditional upon compliance with the National Road Traffic Act (no 93 of 1996) and the Regulations issued there under in so far as it applies to Drivers Licences,
                                Professional Driving Permits, Operators Permits, Overloading, Dangerous Goods, Vehicle Licences, Clearance Certificates, Road worthiness and the continued fitness of vehicles. A full copy of the Act and the Regulations is available from
                                &nbsp;<a href="http://www.polity.org.za/pol/home/" target="_blank" rel="noreferrer" className="italic font-bold text-blue-500 text-sm">http://www.polity.org.za/pol/home/</a>.
                            </div>
                            {/* declaration limits */}
                            <h1 className="py-10">Declaration</h1>
                            <div className="pb-2 italic font-bold text-sm">
                                I, {proposalForm.contact_title} {proposalForm.contact_first_name} {proposalForm.contact_surname}, DECLARE that all particulars and answers in this
                                proposal and appendices are true and complete in every respect, and that no material fact has been suppressed or withheld.
                                I further declare that if such statements and particulars are in the writing of any person other than myself, such person
                                shall be deemed to have been my Agent for the purpose of this Proposal, and I agree that this declaration and the details
                                given shall be the basis of the contract between me and the Company. I further agree to accept a policy subject to the usual
                                conditions prescribed by the Company and endorsed on their policy, and to pay the premium thereunder. I undertake to exercise
                                all ordinary and reasonable precautions for the safety of the property.
                            </div>
                            <div className=" py-5 sm:p-0">
                                <dl>
                                    <div className="py-2 grid grid-cols-3">
                                        <dt>Date</dt>
                                        <dd className="mt-1 sm:mt-0 sm:col-span-2">{fullDate}</dd>
                                    </div>
                                    <div className="py-2 grid grid-cols-3">
                                        <dt >Name of proposer</dt>
                                        <dd className="mt-1 sm:mt-0 sm:col-span-2">{proposalForm.contact_title} {proposalForm.contact_first_name} {proposalForm.contact_surname}</dd>
                                    </div>
                                    <div className="py-2 grid grid-cols-3">
                                        <dt>Siganture</dt>
                                        <dd className="mt-1 sm:mt-0 sm:col-span-2">
                                            <span className="text-xs text-gray-500">Please sign below</span>
                                            <div className="border-gray-400 border-2 w-80">
                                            <SignatureCanvas
                                                penColor='blue'
                                                ref={(ref) => { sigPad = ref }}
                                                canvasProps={{width: 318, height: 150, className: 'sigCanvas'}}
                                            />
                                            </div>

                                            <button className="my-2 button" type="button" onClick={clear}>
                                                Clear
                                            </button>
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                            {Object.keys(errors).length !== 0 && (
                                <h1 className="my-2 text-red-500">Cannot Submit. Please check all fields before sumitting</h1>
                            )}
                            <CustomButton
                                className="button blue"
                                disabled={isSubmitting}
                                submitBool={isSubmitting}
                                type="submit"
                                text={quoteID > 0 ? "Update Quote" : "Create Quote"}
                            />
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
}
